// helper functions to minimize file size - can move out of TinyPicker

function appendChild(parent, child) {
  parent.appendChild(child);
}

function getFirstElementByClass(className) {
  return document.getElementsByClassName(className)[0];
}

const getTodayString = function (date) {
  return `${date.getFullYear()}.${`${date.getMonth() + 1}`.replace(/^(\d)$/, '0$1')}.${`${date.getDate()}`.replace(
    /^(\d)$/,
    '0$1',
  )}`;
};

const dashTodayString = function (date) {
  // for safe
  if (!date) return '';

  return `${date.getFullYear()}-${`${date.getMonth() + 1}`.replace(/^(\d)$/, '0$1')}-${`${date.getDate()}`.replace(
    /^(\d)$/,
    '0$1',
  )}`;
};

function newDateInstance(val) {
  return val ? new Date(val) : new Date();
}

function addClass(el, className) {
  el.classList.add(className);
}

function getTime(date) {
  return date.getTime();
}

function getNumberOfWeeks(date) {
  return Math.ceil((date.getDate() - 1 - date.getDay()) / 7);
}

function removeCalendar(className) {
  const element = getFirstElementByClass(className);
  element && document.body.removeChild(element);
}

function isDateTodayOrFuture(currentDate, checkThisDate) {
  return currentDate && checkThisDate && getTime(currentDate) >= getTime(checkThisDate);
}
let calc = '';
function positionCalendar(calendarElement, shadowElement, isRightOpen) {
  const grandParent = shadowElement.parentElement;
  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = grandParent.getBoundingClientRect();
  calendarElement.style.top = `${elemRect.top - bodyRect.top + elemRect.height + 15}px`;

  if (isRightOpen) {
    calendarElement.style.left = `${elemRect.left - 412}px`;
  } else {
    calendarElement.style.left = `${elemRect.left}px`;
  }

  calc = elemRect.top - bodyRect.top + 25 - 56 - 40;
}

function getDays(passedInDate, date, i, local) {
  const month = {
    name: date.toLocaleString(local, { month: '2-digit' }),
    year: date.getFullYear(),
    weeks: [],
  };
  const newDate = new Date(passedInDate.getFullYear(), passedInDate.getMonth() + i, 1).getMonth();
  while (date.getMonth() === newDate) {
    const week = getNumberOfWeeks(newDateInstance(date));
    if (typeof month.weeks[week] === 'undefined') {
      month.weeks[week] = {};
    }

    const day = newDateInstance(date);
    month.weeks[week][day.getDay()] = {
      date: day,
    };
    date.setDate(date.getDate() + 1);
  }
  return month;
}

function getMonthsInfoForCalendar(passedInDate, monthsToShow, local) {
  const monthsArr = [];
  const year = passedInDate.getFullYear();
  const monthNum = passedInDate.getMonth();
  for (let i = 0; i < monthsToShow; i++) {
    const date = new Date(year, monthNum + i, 1); // Get first day of the month
    const month = getDays(passedInDate, date, i, local); // Get the days that go in the month
    monthsArr.push(month);
  }

  return monthsArr;
}

function TinyPicker(overrides) {
  // eslint-disable-line no-unused-vars
  // console.log(overrides);
  const { firstBox } = overrides;
  const lastBox = overrides.lastBox || {};
  const listeners = [];
  const settings = {
    local: overrides.local || 'en-ko',
    localOpts: overrides.localOpts || {},
    selectPast: overrides.allowPast || false,
    pastMonths: overrides.pastMonths || 0,
    minStartDate: overrides.pastMonths || 0,
    maxStartDate: overrides.pastMonths || 0,
    minSelectableDate: overrides.minSelectableDate || 0,
    maxSelectableDate: overrides.maxSelectableDate || false,
    months: window.innerWidth > 500 ? overrides.months || 2 : 1,
    days: overrides.days || ['일', '월', '화', '수', '목', '금', '토'],
    isRightOpen: overrides.isRightOpen || false,
    periodAllowOneSelect: overrides.periodAllowOneSelect || false,
    disabled: overrides.disabled,
    success: overrides.success || function () {},
    err: overrides.err || function () {},
    isThreeMonthsAgo: overrides.isThreeMonthsAgo || false,
  };

  const initialDateSet = true;
  setDateInEl(overrides.startDate, firstBox, initialDateSet);
  setDateInEl(overrides.endDate, lastBox, initialDateSet);
  // Settings and constants
  const today = newDateInstance(newDateInstance().setHours(0, 0, 0, 0));
  const wroteCss = false;
  const overrideClass = overrides.overrideClass || '';
  const styleClass = `${overrideClass}_tinypicker`;
  const calendarClassName = 'cal';
  const div = 'div';
  const selectedString = 'sel';
  const selectedRangeString = 'inBtw';
  let { startDate } = overrides;
  startDate = firstBox.value === '' ? today : newDateInstance((startDate && startDate.setHours(0, 0, 0, 0)) || '');
  let { endDate } = overrides;
  endDate = newDateInstance((endDate && endDate.setHours(0, 0, 0, 0)) || '');
  function cleanupEventListeners() {
    listeners.forEach(function (cleanupListener) {
      if (typeof cleanupListener === 'function') {
        cleanupListener();
      }
    });
  }
  const { minStartDate } = overrides;
  const { maxStartDate } = overrides;

  function addEventListener(target, type, callback) {
    target.addEventListener(type, callback);
    listeners.push(function () {
      if (target && typeof target.removeEventListener === 'function') {
        target.removeEventListener(type, callback);
      }
    });
  }

  function createElementWithClass(type, className) {
    const el = document.createElement(type);
    el.className = `${className} ${overrideClass}`;
    return el;
  }

  function showCalendar(element, newStartDate) {
    // if (isMobile == 1) {
    //   setTimeout(function () {
    //     element.blur();
    //   }, 20);
    // }
    if (!newStartDate) {
      newStartDate = element === firstBox ? startDate : new Date(endDate.getFullYear(), endDate.getMonth());
    }

    // 2020-01-21 settings.pastMonths 추가
    newStartDate =
      isDateTodayOrFuture(newStartDate, today) || settings.selectPast || settings.pastMonths ? newStartDate : today;

    renderCalendar(element, newStartDate);
    positionCalendar(getFirstElementByClass(calendarClassName), element, settings.isRightOpen);

    // Close the calendar listener
    ['click', 'touchend'].forEach(function (event) {
      addEventListener(document, event, function (e) {
        const el = e.target;
        const calendarEl = getFirstElementByClass(calendarClassName);
        if (calendarEl && !calendarEl.contains(getFirstElementByClass(el.className)) && el !== document.activeElement) {
          removeCalendar(calendarClassName);
          // SJ
          // var wmpoContent = document.querySelector('#wmpoContent');
          // wmpoContent.classList.remove('cal-padding-bottom');
        }
      });
    });
  }

  function renderCalendar(element, newDate) {
    removeCalendar(calendarClassName);

    const calendarObj = getMonthsInfoForCalendar(newDate, settings.months, settings.local);
    const sinceDate = element !== firstBox && isDateTodayOrFuture(startDate, today) ? startDate : today;

    const calendarWidget = createElementWithClass(div, calendarClassName);
    appendChild(calendarWidget, getChevrons(element, calendarObj));
    appendChild(document.body, calendarWidget);

    calendarObj.forEach(function (month) {
      const monthDiv = createElementWithClass(div, 'mnt');

      const monthHeader = createElementWithClass('div', 'hed');
      const monthHeaderTitle = createElementWithClass('h2', 'title-month');
      const monthName = (monthHeaderTitle.innerHTML = `${month.year}.${`0${newDate.getMonth() + 1}`.slice(-2)}`);

      appendChild(monthHeader, monthHeaderTitle);
      appendChild(monthDiv, monthHeader);

      const calendarContainer = createElementWithClass(div, 'day-wrapper');

      settings.days.forEach(function (day) {
        const dayEl = createElementWithClass('span', 'text-week');

        dayEl.innerHTML = day;
        appendChild(monthHeader, dayEl);
      });

      appendChild(calendarContainer, createCalInnerWorkings(month.weeks, sinceDate, element));
      appendChild(monthDiv, calendarContainer);
      appendChild(calendarWidget, monthDiv);
    });
  }

  function handleCalendarState(shadowElement, date) {
    if (shadowElement === firstBox) {
      startDate = date;
      endDate = startDate;

      // true 면 startDate 만 넘길 수 있음.
      if (settings.periodAllowOneSelect) {
        settings.success(startDate, undefined);
        removeCalendar(calendarClassName);
        return;
      }

      if (lastBox.nodeType) {
        lastBox.value = ''; // If user reenters startDate, force reselect of enddate
        lastBox.innerHTML = '';
        lastBox.focus();
      } else {
        removeCalendar(calendarClassName);
        settings.success(startDate);
      }
    } else {
      endDate = date;
      removeCalendar(calendarClassName);
      shadowElement.classList.remove('err');
      settings.success(startDate, endDate);

      // SJ
      // var wmpoContent = document.querySelector('#wmpoContent');
      // wmpoContent.classList.remove('cal-padding-bottom');
    }
  }

  function getChevrons(element, calendarObj) {
    const navWrapper = createElementWithClass(div, 'nav');

    appendChild(navWrapper, createElementWithClass('span', 'rChev'));
    appendChild(navWrapper, createElementWithClass('span', 'lChev'));

    addEventListener(navWrapper, 'click', function (e) {
      let monthChange;
      if (e.target.className === `rChev ${overrideClass}`) {
        monthChange = 1;
      } else if (e.target.className === `lChev ${overrideClass}`) {
        monthChange = -1;
      } else {
        return;
      }
      const firstWeek = calendarObj[0].weeks[0];
      const { date } = firstWeek[Object.keys(firstWeek)[0]];
      const newStartDate = newDateInstance(date.setMonth(date.getMonth() + monthChange));
      if (e.target.localName == 'span') {
        showCalendar(element, newStartDate);
      }
    });
    return navWrapper;
  }

  function createCalInnerWorkings(weeks, sinceDate, element) {
    const calendarBody = createElementWithClass(div, 'tp-cc');

    weeks.forEach(function (week) {
      for (let i = 0; i < 7; i++) {
        const currentDate = week[i] && week[i].date;
        const dayOfWeekEl = createElementWithClass('button', 'day');

        if (typeof currentDate === 'undefined') {
          appendChild(calendarBody, dayOfWeekEl);
        } else {
          // 기본으로 전부 disb
          dayOfWeekEl.className = `disb ${overrideClass}`;
          dayOfWeekEl.setAttribute('disabled', 'disabled');

          const currentTime = getTime(currentDate);
          const selectPastDate = new Date(today.getFullYear(), today.getMonth() - settings.pastMonths, today.getDate());
          const minSelectableDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + settings.minSelectableDate,
          );
          const threeMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
          if (settings.maxSelectableDate)
            var maxSelectableDate = new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate() + settings.maxSelectableDate,
            );

          // currentDate가 today 보다 크거나 같고 엘리먼트가 퍼스트박스이면 active 추가
          // currentDate가 startDate 보다 크거나 같으면 엑티브 추가
          // settings.selectPast true이고 엘리먼트가 퍼스트박스이면 active 추가
          // if ((currentDate >= today && element === firstBox) || currentDate >= startDate || (settings.selectPast && element === firstBox)) {
          // 2020-01-21 settings.pastMonths 추가
          if (
            (!settings.pastMonths && currentDate >= today && element === firstBox) ||
            currentDate >= startDate ||
            (settings.selectPast && element === firstBox) ||
            (currentDate >= selectPastDate && element === firstBox) ||
            (currentDate >= startDate && element === firstBox && element === lastBox)
          ) {
            dayOfWeekEl.className = `active ${overrideClass}`;
            dayOfWeekEl.removeAttribute('disabled');
            if (!settings.selectPast && currentDate <= startDate) dayOfWeekEl.setAttribute('disabled', 'disabled');
            addEventListener(dayOfWeekEl, 'click', setDateInEl.bind(this, currentDate, element, false));

            // startDate 날짜에 최소 선택가능 날짜가 있다면 minStartDate 를 추가한다.
            if (currentDate < minStartDate) {
              dayOfWeekEl.className = `disb ${overrideClass}`;
              dayOfWeekEl.setAttribute('disabled', 'disabled');
            }
            // 특정 날짜 이후 부터 선택이 안되게 하려면 maxStartDate 를 추가한다.
            if (currentDate > maxStartDate) {
              dayOfWeekEl.className = `disb ${overrideClass}`;
              dayOfWeekEl.setAttribute('disabled', 'disabled');
            }

            // endDate날짜에 최소 선택가능 날짜가 있다면 minSelectableDate를 추가한다.
            if (startDate < currentDate && currentDate <= minSelectableDate && element === lastBox) {
              dayOfWeekEl.className = `disb ${overrideClass}`;
              dayOfWeekEl.setAttribute('disabled', 'disabled');
            }
            // endDate날짜에 최대 선택가능 날짜가 있다면 maxSelectableDate 추가한다.
            if (startDate < currentDate && currentDate > maxSelectableDate && element === lastBox) {
              dayOfWeekEl.className = `disb ${overrideClass}`;
              dayOfWeekEl.setAttribute('disabled', 'disabled');
            }

            // 현재 시점으로 3개월 이전 일자는 선택 불가하도록 비활성화
            if (settings.isThreeMonthsAgo && currentDate < threeMonthsAgo) {
              dayOfWeekEl.className = `disb ${overrideClass}`;
              dayOfWeekEl.setAttribute('disabled', 'disabled');
            }

            // Add Highlights to days
            if (endDate > currentDate && startDate < currentDate) {
              addClass(dayOfWeekEl, selectedRangeString);
            } else if (getTime(endDate) === currentTime || currentTime === getTime(startDate)) {
              addClass(dayOfWeekEl, selectedString);
            }
          }

          dayOfWeekEl.innerHTML = currentDate.getDate();
          dayOfWeekEl.classList.add('day');
          dayOfWeekEl.setAttribute('time', currentTime);
          appendChild(calendarBody, dayOfWeekEl);
          hoverRange(dayOfWeekEl, element);
        }
      }
    });

    return calendarBody;
  }

  function hoverRange(el, inputClicked) {
    addEventListener(el, 'mouseover', function (e) {
      const days = document.getElementsByClassName('day');
      const hoverTime = parseInt(e.target.getAttribute('time'), 10);
      const startTime = getTime(startDate);

      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const elTime = parseInt(day.getAttribute('time'), 10);

        day.classList.remove(selectedString);
        day.classList.remove(selectedRangeString);
        if (inputClicked === lastBox && elTime < hoverTime && elTime > startTime) {
          addClass(day, selectedRangeString);
        } else if (hoverTime === elTime || (elTime === startTime && inputClicked !== firstBox)) {
          addClass(day, selectedString);
        }
      }
    });
  }

  // Specific helpers for TinyPicker

  function setDateInEl(date, shadowElement, initial) {
    initial = initial || false;
    if (!date && shadowElement instanceof HTMLElement) {
      shadowElement.value = '';
      shadowElement.innerHTML = '';
      shadowElement.removeAttribute('date');
    }
    if (date instanceof Date && shadowElement instanceof HTMLElement) {
      /* shadowElement.value = date.toLocaleDateString(settings.local, settings.localOpts); */
      shadowElement.value = getTodayString(date);
      shadowElement.innerHTML = date.toLocaleDateString(settings.local, settings.localOpts);
      shadowElement.setAttribute('date', getTime(date));
    }
    if (!initial) {
      handleCalendarState(shadowElement, date);
    }
  }

  function userInputedDateHandler(element) {
    const val = element.value;
    const userInputedDate = val && newDateInstance(val);
    const instanceOfDate = userInputedDate instanceof Date;

    if (instanceOfDate || (instanceOfDate && !isDateTodayOrFuture(userInputedDate, startDate))) {
      element.value = '';
      settings.err();
    }
    isDateTodayOrFuture(userInputedDate, today) && setDateInEl(userInputedDate, element, false);
  }

  // Init listeners to properly display calendar
  this.init = function () {
    [firstBox, lastBox].forEach(function (element) {
      if (!element.nodeType || settings.disabled) return;
      addEventListener(element, 'focus', function (e) {
        // !wroteCss && writeCSSToHead(overrideClass, styleClass);
        // wroteCss = true;
        showCalendar(e.target);
      });
      // TODO:Should this be here??? I can do this somewhere else
      let timer;
      addEventListener(element, 'keydown', function (e) {
        clearTimeout(timer);
        timer = setTimeout(function () {
          userInputedDateHandler(e.target);
        }, 1000);
      });
    });
  };

  this.cleanup = function () {
    removeCalendar(calendarClassName);
    // removeCSSFromHead(styleClass);
    cleanupEventListeners();
  };
}

export default TinyPicker;
