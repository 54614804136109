import Spinner from '@src/components/molecules/Spinner';
import { numberWithCommas } from '@src/library/Utils';
import { CallGetPointOfflineEvent, CallGetPomiStores } from '@src/library/request/callPoint';
import { OfflineDetailInit, OfflineDetailType, PomiStoreListType, StoreStatusKo } from '@src/types/pointType';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PointOffline = () => {
  const location = useLocation();
  const storeStatus = location?.state || ''; // 수정 페이지에서 받아오는 select value
  const [pomiStore, setPomiStore] = useState<PomiStoreListType[] | null>(null);
  const [detail, setDetail] = useState<OfflineDetailType>(OfflineDetailInit);
  const [currentStoreID, setCurrentStoreID] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentStoreRef = useRef<string | number>();

  // 매장 전체 텍스트에서 매장번호만 가져오기
  const extractNumber = (str: string) => {
    const regex = /\[(\d+)\]/;
    const match = str.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  };

  const handleStoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    getPointOfflineEvent(Number(extractNumber(selectedValue)));
    currentStoreRef.current = e.target.value;
  };

  const getPointOfflineEvent = async (storeID: number) => {
    const { result, data } = await CallGetPointOfflineEvent(storeID);
    if (result.code === 1) {
      setDetail(data);
      setCurrentStoreID(storeID);
      setLoading(false);
    } else {
      console.log('실패: code =', result.code);
      setLoading(false);
    }
  };

  const getPomiStores = async () => {
    setLoading(true);
    const { result, data } = await CallGetPomiStores();
    if (result.code === 1 && data) {
      setPomiStore(data);
      if (storeStatus) {
        getPointOfflineEvent(Number(extractNumber(storeStatus)));
        currentStoreRef.current = storeStatus;
        navigate(location.pathname, { replace: true }); // location.state 초기화
      } else {
        getPointOfflineEvent(Number(data[0].ID));
        currentStoreRef.current = `[${StoreStatusKo[Number(data[0]?.status)]}] [${data[0]?.ID}] ${data[0]?.name}`;
      }
    } else {
      setLoading(false);
      console.log('실패: code =', result.code, result.message);
    }
  };

  useEffect(() => {
    getPomiStores();
  }, []);

  return (
    <div className="pointPageWrap amount">
      <p className="notiPlus">※ 위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.</p>
      <div className="searchArea">
        <select onChange={(e) => handleStoreChange(e)} value={storeStatus || currentStoreRef.current}>
          {pomiStore &&
            pomiStore.map((v, idx) => {
              const { ID, name, status } = v;
              return (
                <option key={`${ID}-${idx}`} value={`[${StoreStatusKo[Number(status)]}] [${ID}] ${name}`}>
                  [{StoreStatusKo[Number(status)]}] [{ID}] {name}
                </option>
              );
            })}
        </select>
      </div>
      {pomiStore && (
        <>
          <div className="detailArea">
            <dl>
              <dt>적립 방법</dt>
              <dd>{detail.reserveText}</dd>
              <dt>유효기간</dt>
              <dd>
                {detail.pointValidTerm === 0
                  ? '제한 없음'
                  : `적립일로 부터 ${numberWithCommas(detail.pointValidTerm)}일`}
              </dd>
              <dt>최대 적립 금액</dt>
              <dd>
                {detail.maxReserveAmount === 0
                  ? '제한 없음'
                  : `최대 ${numberWithCommas(detail.maxReserveAmount)}P 적립`}
              </dd>
            </dl>
          </div>

          <div className="fixedBtnGroup">
            <button
              type="button"
              id="btnSubmit"
              className="btnDone"
              onClick={() => navigate(`/point/offline/${currentStoreID}`, { state: currentStoreRef.current })}
            >
              수정
            </button>
          </div>
        </>
      )}
      <Spinner show={loading} />
    </div>
  );
};

export default PointOffline;
