import { HOST_API_AUTH, CLIENTID } from '@src/library/Constants';

export const goLoginPage = (): void => {
  const { href, origin } = window.location;
  const checkUrl = `${origin}/login/check?redirectUrl=${href}`;
  window.location.replace(`${HOST_API_AUTH}/signin?redirectUrl=${encodeURIComponent(checkUrl)}&clientID=${CLIENTID}`);
};

export const getRootDomain = (): string => {
  const { hostname } = window.location;
  const hostParts = hostname.split('.');
  if (hostParts.length > 2) {
    hostParts.shift();
    return `.${hostParts.join('.')}`;
  }
  return `.${hostname}`;
};

// 넘버 값에 천단위 콤마 찍어주기
export const numberWithCommas = (x: number | string) => {
  if (!x) return '';
  return x.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

// 넘버 값에 천단위 콤마 찍어주기 - 값이 0일때 0 리턴
export const numberWithCommasZero = (x: number | string) => {
  if (!x) return 0;
  return x.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

// 값이 스트링일때 콤마 뺴고 넘버로 바꿔줌
export const numberWithCommasToNum = (x: number | string) => {
  if (!x) return 0;
  return Number(`${x}`.replace(/,/g, ''));
};

// 모바일 디바이스로 접속했는지 체크 (모바일 웹, 모바일 앱 둘다 체크해서 한개라도 true경우 true반환)
export const isMobileDevice = () => {
  const { userAgent } = window.navigator;
  const isMobileWeb = /iPhone|iPad|iPod|Android/i.test(userAgent);
  const isMobileApp = userAgent.indexOf('Cupping-Biz') > -1;
  return isMobileWeb || isMobileApp;
};

export const throttle = (fn: (e: any) => void, wait = 300) => {
  let inThrottle: boolean;
  let lastFn: ReturnType<typeof setTimeout>;
  let lastTime: number;

  return function throttleAction(this: any, ...args: any) {
    if (!inThrottle) {
      fn.apply(this, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(this, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

// 매장 전체 텍스트에서 매장번호만 가져오기
export const extractNumber = (str: string) => {
  const regex = /\[(\d+)\]/;
  const match = str.match(regex);
  if (match && match[1]) {
    return Number(match[1]);
  }
  return null;
};
