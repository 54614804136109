/**
 * 예제) 한 그룹당 3개 제한이며 2페이지가 선택된 경우
 *
 *     v (page)
 * [1, 2, 3] | [4, 5, 6] | [7,8]
 *
 * pageCount: 1,2,3,4,5,6,7,8 => 8
 * groupCount: [1,2,3], [4,5,6], [7,8] => 3
 * groupIdx: [1,*2*,3] => 0
 * currPages: [1,2,3]
 * currPageLength: [1,2,3] => 3
 * */

export type PageCount = {
  limit: number;
  totalCount: number;
};

export type PagingGroup = {
  pageCount: number;
  maxSize: number;
};

export type PagingGroupIdx = {
  page: number;
  maxSize: number;
};

export type PagingGroupStartEndPage = {
  groupIdx: number;
  maxSize: number;
};

export interface CurrPages extends PageCount {
  page: number;
  maxSize: number;
}

// 페이지의 갯수를 가져온다.
export const pageCount = ({ limit, totalCount }: PageCount) => {
  return Math.ceil(totalCount / limit);
};

// 페이징 그룹의 갯수 [1,2,3] [4,5,6] -> return 2
export const groupCount = ({ pageCount: inPageCount, maxSize }: PagingGroup) => {
  return Math.ceil(inPageCount / maxSize);
};

// 페이지가 위치한 페이징 그룹의 index
export const groupIdx = ({ page, maxSize }: PagingGroupIdx) => {
  // 맨 처음 그룹의 마지막 페이지를 의미한다. (3개 페이지 중 3페이지)
  if (page % maxSize === 0) return page / maxSize - 1;

  return Math.floor(page / maxSize);
};

// 페이징 그룹의 첫번째 페이지
export const groupStartPage = ({ groupIdx: idx, maxSize }: PagingGroupStartEndPage) => {
  return idx * maxSize + 1;
};

// 페이징 그룹의 마지막 페이지
export const groupEndPage = ({ groupIdx: idx, maxSize }: PagingGroupStartEndPage) => {
  if (idx === -1) return 1;
  return (idx + 1) * maxSize;
};

// maxSize 이상 일 때 현재 페이지가 중앙에 위치
export const getCurrPages = ({ page, maxSize, limit, totalCount }: CurrPages): number[] => {
  const maxPage = pageCount({ limit, totalCount });

  let startPage = page - Math.floor(maxSize / 2); // 현재 페이지 그룹의 첫 번째 페이지
  startPage = Math.max(startPage, 1); // 첫 번째 페이지 처리
  startPage = Math.min(startPage, maxPage - maxSize + 1); // 마지막 페이지 그룹 처리

  if (startPage < 1) {
    startPage = 1;
  }
  const paging: number[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startPage; i < startPage + maxSize; i++) {
    if (i > maxPage) break;
    paging.push(i);
  }

  return paging;
};
