import React, { useEffect, useMemo } from 'react';
import { AWS_IMAGE_URL } from '@src/library/Constants';
import { getCurrPages, groupEndPage, groupIdx, groupStartPage, pageCount } from './paginationFunc';

export type Page = number;
export type Limit = number;

interface PagionationProps {
  page?: Page;
  limit?: Limit; // items per page
  totalCount?: number;
  count?: number;
  totalPage?: number;
  maxSize?: number; // paging items
  onSearch: (page: number) => void;
}

const CommonPagination = (props: PagionationProps) => {
  const { page = 1, limit = 10, maxSize = 1, totalCount = 0, onSearch = () => {}, count = 0, totalPage = 0 } = props;
  const maxPage = pageCount({ limit, totalCount });
  const currPages = getCurrPages({ page, maxSize, limit, totalCount });

  const pageElem = useMemo(() => {
    return currPages.map((p) => (
      <button
        type="button"
        key={`page${p}`}
        className={`num ${p === page ? 'active' : ''}`}
        onClick={(e) => onClickPage(p)}
      >
        {p}
      </button>
    ));
  }, [currPages]);

  if (totalCount === 0) return null;

  const onClickPage = (_page: number) => {
    // active 된 페이지를 클릭한 경우
    if (page === _page) return;

    onSearch(_page);
  };

  return (
    <div className="commonPagination">
      <button
        type="button"
        className="btnPrev"
        disabled={page === 1}
        onClick={(e) => {
          if (page === 1) return;
          onClickPage(page - 1);
        }}
      >
        <img src="//image.thecupping.co.kr/partners/mobile/page-front-prev.svg" alt="이전 리스트" />
      </button>
      {pageElem}
      <button
        type="button"
        className="btnNext"
        disabled={page === maxPage}
        onClick={(e) => {
          if (page === maxPage) return;
          onClickPage(page + 1);
        }}
      >
        <img src="//image.thecupping.co.kr/partners/mobile/page-front-next.svg" alt="다음 리스트" />
      </button>
    </div>
  );
};

export default CommonPagination;
