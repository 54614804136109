import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from '@src/routes/root';
import ErrorPage from '@src/routes/error-page';
import PointOnline from '@src/pages/point/online/online';
import PointOffline from '@src/pages/point/offline/offline';
import PointList from '@src/pages/point/list/list';
import { ModeType } from '@type/pointType';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import PointOnlineDetail from './pages/point/online/detail';
import PointOnlineAdd from './pages/point/online/add';
import PointAmountSetting from './pages/point/amount/amount';
import PointOfflineEdit from './pages/point/offline/edit';
import PointAmountEdit from './pages/point/amount/edit';
import PointListDetail from './pages/point/list/detail';
import DownloadPomi from './pages/download/pomi';
import Logout from './pages/login/out';
import LoginCheck from './pages/login/check';
import ServiceTerms from './pages/terms/service';
import PrivacyTerms from './pages/terms/privacy';
import { TableOrder } from './pages/table/setting/setting';
import { TableOrderEdit } from './pages/table/setting/edit';
import { TableMenuPreview } from './pages/table/setting/preview';
import { TableList } from './pages/table/list/list';
import { TableListAdd } from './pages/table/list/add';
import { queryErrorHandler } from './library/queryAPI';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60 * 5,
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: AxiosError) => queryErrorHandler(error),
  }),
  mutationCache: new MutationCache({
    onError: (error: AxiosError) => queryErrorHandler(error),
  }),
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'point',
        children: [
          {
            path: 'online',
            loader: () => {
              return { title: '자사앱 포인트 설정', isNeedLogin: true, useSiteID: true };
            },
            children: [
              {
                index: true,
                element: <PointOnline />,
              },
              {
                path: 'detail/:eventID',
                element: <PointOnlineDetail />,
                loader: () => {
                  return { title: '자사앱 포인트 상세', isNeedLogin: true, backUrl: true };
                },
              },
              {
                path: 'add/*',
                element: <PointOnlineAdd mode={ModeType.new} />,
                loader: () => {
                  return {
                    title: '자사앱 포인트 등록',
                    isNeedLogin: true,
                    backUrl: true,
                    message: '페이지를 벗어나시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
                  };
                },
              },
              {
                path: 'edit/:eventID/*',
                element: <PointOnlineAdd mode={ModeType.edit} />,
                loader: () => {
                  return {
                    title: '자사앱 포인트 수정',
                    isNeedLogin: true,
                    backUrl: true,
                    message: '페이지를 벗어나시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
                  };
                },
              },
            ],
          },
          {
            path: 'offline',
            loader: () => {
              return { title: '오프라인 포인트 설정', isNeedLogin: true, useSiteID: true };
            },
            children: [
              {
                index: true,
                element: <PointOffline />,
              },
              {
                path: ':storeID',
                element: <PointOfflineEdit />,
                loader: () => {
                  return { title: '오프라인 포인트 설정', isNeedLogin: true };
                },
              },
            ],
          },
          {
            path: 'list',
            loader: () => {
              return { title: '포인트 내역', isNeedLogin: true, useSiteID: true };
            },
            children: [
              {
                index: true,
                element: <PointList />,
              },
              {
                path: ':storeID',
                element: <PointListDetail />,
                loader: () => {
                  return { title: '포인트 내역 상세', isNeedLogin: true, backUrl: true };
                },
              },
            ],
          },
          {
            path: 'amount',
            loader: () => {
              return { title: '최소 사용 금액 설정', isNeedLogin: true, useSiteID: true };
            },
            children: [
              {
                index: true,
                element: <PointAmountSetting />,
              },
              {
                path: ':storeID',
                element: <PointAmountEdit />,
                loader: () => {
                  return { title: '최소 사용 금액 설정', isNeedLogin: true };
                },
              },
            ],
          },
        ],
      },
      {
        path: 'download',
        children: [
          {
            path: 'pomi',
            element: <DownloadPomi />,
            loader: () => {
              return { title: '포포 다운로드', isNeedLogin: false };
            },
          },
        ],
      },
      {
        path: 'table',
        children: [
          {
            path: 'setting',
            loader: () => {
              return { title: '테이블 주문 설정', isNeedLogin: true };
            },
            children: [
              {
                index: true,
                element: <TableOrder />,
              },
              {
                path: ':storeID',
                element: <TableOrderEdit />,
                loader: () => {
                  return { title: '테이블 주문 설정', isNeedLogin: true };
                },
              },
              {
                path: 'preview',
                element: <TableMenuPreview />,
                loader: () => {
                  return { title: '메뉴판 미리보기', backUrl: true, isNeedLogin: false };
                },
              },
            ],
          },
          {
            path: 'list',
            loader: () => {
              return { title: '테이블 관리', isNeedLogin: true, wrapClassName: 'table' };
            },
            children: [
              {
                index: true,
                element: <TableList />,
              },
              {
                path: 'add/:storeID/*',
                element: <TableListAdd />,
                loader: () => {
                  return { title: '테이블 일괄 추가', backUrl: true, isNeedLogin: true };
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    children: [
      { path: 'check', element: <LoginCheck /> },
      { path: 'out', element: <Logout /> },
    ],
  },
  {
    path: 'terms',
    children: [
      { path: 'service', element: <ServiceTerms /> },
      { path: 'privacy', element: <PrivacyTerms /> },
    ],
  },
]);

const App = (): React.ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
