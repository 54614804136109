import React, { useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { bizStorage } from '@src/library/bizStorage';
import { HOST_BIZ } from '@src/library/Constants';
import { Outlet, useMatches, useSearchParams, useLocation } from 'react-router-dom';
import { StorageCompanyType } from '@src/types/storageType';
import { goLoginPage, getRootDomain } from '@src/library/Utils';
import { childrenPropsType } from '@src/types/layout';
import { CallGetInit } from '@src/library/request/callMain';
import DefaultLayout from '@src/components/templates/DefaultLayout';
import Spinner from '@src/components/molecules/Spinner';

const Root = () => {
  const matches = useMatches();
  const location = useLocation();
  const { pathname } = window.location;
  const [searchParams] = useSearchParams();
  const passedCode = searchParams.get('code');
  const layoutState = searchParams.get('layout');
  const currentData: childrenPropsType | undefined = matches.find((v) => v.pathname === pathname)
    ?.data as childrenPropsType;
  const {
    title = '',
    isNeedLogin = false,
    backUrl = false,
    message = '',
    useSiteID = false,
    wrapClassName = '',
  } = currentData || {};

  const menu = bizStorage.getItem('menu');
  const company: StorageCompanyType = bizStorage.getItem('company');

  const token = Cookies.get('token');
  const companyID = Cookies.get('companyID');

  const [isLogin, setIsLogin] = useState<string | undefined>(Cookies.get('isLogin'));
  const [load, setLoad] = useState<boolean>(true);

  const init = async () => {
    if (!companyID && company) {
      bizStorage.removeItem('company');
      bizStorage.removeItem('storeList');
      bizStorage.removeItem('dealList');
    }
    if ((!isNeedLogin && menu && isLogin !== '1') || (isLogin === '1' && company && String(company.ID) === companyID)) {
      setLoad(false);
      return;
    }
    const { result, data } = await CallGetInit();
    if (result.code === 1) {
      bizStorage.setItem('menu', data.menuList);
      if (data.isLogin === 1) {
        bizStorage.setItem('company', data.company);
        bizStorage.setItem('storeList', data.storeList);
        bizStorage.setItem('dealList', data.dealList);
        Cookies.set('isLogin', '1', { domain: getRootDomain() });
        Cookies.set('companyID', data.company.ID, { domain: getRootDomain() });
        setIsLogin('1');
      } else if (data.isLogin === 0 && isNeedLogin) {
        goLoginPage();
      }
      setLoad(false);
    }
  };

  const deniedSiteIDPage = useMemo(() => {
    if (load || !company) return false;
    if (useSiteID && isLogin && !company.siteID) return true;
    return false;
  }, [useSiteID, isLogin, company, load]);

  const spinnerState = useMemo(() => {
    if (load || deniedSiteIDPage) return true;
    if (isLogin !== '1') {
      if (passedCode || isNeedLogin) return true;
    }
    return false;
  }, [isLogin, passedCode, isNeedLogin, load, deniedSiteIDPage]);

  useEffect(() => {
    if (!token) {
      // 토큰 없으면 biz1 메인으로 이동
      window.location.replace(HOST_BIZ);
      return;
    }
    init();
  }, [location]);

  useEffect(() => {
    if (deniedSiteIDPage) {
      alert('위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.');
      window.location.replace(HOST_BIZ);
    }
  }, [deniedSiteIDPage]);

  if (spinnerState) return <Spinner />;

  const isViewLayout = () => {
    if (layoutState === 'none') return <Outlet />;
    return (
      <DefaultLayout title={title} backUrl={backUrl} message={message} wrapClassName={wrapClassName}>
        <Outlet />
      </DefaultLayout>
    );
  };

  return <>{isViewLayout()}</>;
};

export default Root;
