import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { ApiParamType, ResponseType } from '@src/types/apiType';
import Cookies from 'js-cookie';
import { setFormData } from './API';
import { HOST_API, HOST_API_AUTH, isDevEnv } from './Constants';

export const queryRequest = async <T = undefined>(param: ApiParamType): Promise<ResponseType<T>> => {
  const { url, method, data, host } = param;
  const token = Cookies.get('token');

  const config: AxiosRequestConfig = {
    baseURL: HOST_API,
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      token,
    },
  };

  if (host === 'auth') {
    config.baseURL = HOST_API_AUTH;
  }

  if (data && (method === 'post' || method === 'put')) {
    config.data = setFormData(data);
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  if (method === 'get') {
    config.params = data;
  }

  if (data && method === 'postJson') {
    config.method = 'post';
    config.data = data;
  }

  const response = await axios(config);
  return response.data;
};

// 전역에서 공통으로 error 처리
export const queryErrorHandler = (error: any) => {
  if (isDevEnv) {
    if (error.response) {
      console.log('request 요청 오류 발생 - 상태 코드 2xx 외의 값', error.response.status);
    } else if (error.request) {
      console.log('request 요청 오류 발생 - 응답이 전송되지 않음', error.request);
    } else {
      console.log('request 요청 오류 발생 - 요청 설정 중 문제 발생', error.message);
    }
    console.log('요청 한 config: ', error.config);
  }
  // 매니저 접근 불가
  if (error.response.status === 401) {
    alert(error.response.data.result.message);
    window.history.back();
  }
  console.log('error', error);
};
