import { StoreStatus, ModeType } from './pointType';

/*
  테이블 주문 설정
*/
export enum TablePayType {
  app = 1, // 바로결제
  site, // 현장결제
}
export enum TableAppType {
  wmpo = 1,
  wmpoPlus,
}

export interface StoreTableDetailType {
  storeID: number;
  isTable: number; // 테이블 주문 사용 (1: 사용, 0: 사용 안함)
  menuType: number; // 메뉴판
  payType?: TablePayType; // 결제방법 (1: 바로결제, 2: 현장결제)
  appType?: TableAppType; // 주문접수 채널 (1: 위메프오, 2: 자사앱)
  brandID: number; // 주문채널 (0: 위메프오, 0 < 자사앱)
  // 화면용
  status?: StoreStatus; // 매장상태
  mode?: ModeType;
}

export const StoreTableDetailInit: StoreTableDetailType = {
  storeID: 0,
  isTable: 0,
  menuType: 2,
  payType: TablePayType.app,
  appType: TableAppType.wmpo,
  brandID: 0,
  // 화면용
  status: StoreStatus.sale,
  mode: ModeType.new,
};

export interface StoreTablePutType {
  isTable: number;
  menuType: number;
  payType?: TablePayType;
  appType?: TableAppType;
}

/*
  react-query queryKey
*/
export const TABLEORDER_KEYS = {
  DETAIL: 'tableSettingsDetail',
  LIST: 'tableList',
};
