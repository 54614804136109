import React from 'react';

interface CheckBoxProps {
  id: string;
  miniSize?: boolean;
  label?: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CommonCheckBox = (props: CheckBoxProps) => {
  const { id, miniSize, label, value, onChange } = props;

  return (
    <label htmlFor={id} className={`commonCheckBox ${miniSize ? 'mini' : ''}`}>
      <input type="checkbox" id={id} checked={value} onChange={onChange} />
      <span className="icoCheck" />
      <span className="label">{label}</span>
    </label>
  );
};

export default CommonCheckBox;
