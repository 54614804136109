import useBackDetection from '@src/hooks/useBackDetection';
import { AWS_IMAGE_URL } from '@src/library/Constants';
import { extractNumber } from '@src/library/Utils';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const TableMenuPreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectStore = '', prev = null } = location?.state || {};
  const storeID = extractNumber(selectStore);

  const checkGoBack = () => {
    navigate(`/table/setting/${storeID}`, { state: { selectStore, prev } });
  };

  useBackDetection(checkGoBack);

  return (
    <div className="table-page-wrap preview">
      <div className="detailFormArea">
        <div className="formItem">
          <span className="title">1단 스크롤</span>
          <img src={`${AWS_IMAGE_URL}/image/img-qrtableorder-menutype01@2x.png`} alt="1단 스크롤" />
        </div>
        <div className="formItem">
          <span className="title">2단 스크롤</span>
          <img src={`${AWS_IMAGE_URL}/image/img-qrtableorder-menutype02@2x.png`} alt="2단 스크롤" />
        </div>
        <div className="formItem">
          <span className="title">3단 스크롤</span>
          <img src={`${AWS_IMAGE_URL}/image/img-qrtableorder-menutype03@2x.png`} alt="3단 스크롤" />
        </div>
      </div>
    </div>
  );
};
