import React, { useEffect, useState } from 'react';
import { AddPropsType } from '@src/pages/point/online/addBasic';
import { useNavigate } from 'react-router-dom';
import { CallGetPomiStores } from '@library/request/callPoint';
import CommonCheckBox from '@components/molecules/checkBox/CheckBox';
import Spinner from '@components/molecules/Spinner';
import { StoreStatusKo } from '@type/pointType';

const PointOnlineAddStore: React.FC<AddPropsType> = (props) => {
  const { formControl, onClickSave } = props;
  const navigate = useNavigate();
  const { setValue, watch } = formControl;

  const [storeList, setStoreList] = useState([]);
  const [idList, setIdList] = useState<string[]>(watch('storeIDs') || []);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);

  const getStoreList = async () => {
    try {
      setLoading(true);
      const { data } = await CallGetPomiStores();
      setStoreList(data);
      // console.log(idList, data, idList.length === data.length);
      if (idList && data && idList?.length === data?.length) {
        setIsCheckAll(true);
      }
    } catch (e) {
      setStoreList([]);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    if (!idList.includes(id)) {
      setIdList([...idList, id]);
    } else {
      setIdList(idList.filter((item) => item !== id));
    }
  };

  const onClickSaveBtn = () => {
    onClickSave();
  };

  useEffect(() => {
    setValue('storeIDs', idList);
  }, [idList]);

  useEffect(() => {
    getStoreList();
  }, []);

  return (
    <div className="pointPageWrap online">
      <div className="stepTitlebox">
        <p>포인트 적용 매장 설정</p>
        <span>
          <em>2</em> &nbsp;/&nbsp; 2
        </span>
      </div>
      <div className="detailFormArea">
        <div className="formItem store">
          <div className="storeTitle">
            <span>매장 선택</span>
            {storeList && storeList?.length > 1 && (
              <CommonCheckBox
                id="noDate"
                label="전체 선택"
                value={isCheckAll}
                onChange={(e) => {
                  const { checked } = e.target;
                  setIsCheckAll(checked);
                  if (checked) {
                    const getIds = storeList.map((store) => store.ID);
                    setIdList(getIds);
                  } else {
                    setIdList([]);
                  }
                }}
              />
            )}
          </div>
          {storeList?.length ? (
            storeList.map((store) => {
              const { ID, name, status } = store;
              return (
                <div className="storeContents" key={ID}>
                  <div className="storeStatus">
                    <span>{`[${StoreStatusKo[status]}] [${ID}]`}</span>
                    <p>{name}</p>
                  </div>
                  <CommonCheckBox id={ID} onChange={onChangeCheckBox} value={idList.includes(ID)} />
                </div>
              );
            })
          ) : (
            <p className="noListBox">조회된 매장이 없습니다.</p>
          )}
        </div>
        <div className="fixedBtnGroup">
          <button type="button" id="btnSubmit" className="btnEdit" onClick={() => navigate(-1)}>
            이전
          </button>
          <button type="button" id="btnSubmit" className="btnDone" onClick={onClickSaveBtn} disabled={!idList.length}>
            저장
          </button>
        </div>
      </div>
      <Spinner show={loading} />
    </div>
  );
};

export default PointOnlineAddStore;
