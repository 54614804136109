import React, { useEffect, useState, useRef } from 'react';
import {
  CallGetPointMinAmount,
  CallGetPomiStores,
  minAmountType,
  pointMinAmountType,
} from '@src/library/request/callPoint';
import { useLocation, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '@src/library/Utils';
import { PomiStoreListType, StoreStatusKo } from '@type/pointType';
import Spinner from '@src/components/molecules/Spinner';
import { HOST_BIZ } from '@src/library/Constants';

const getInitData = (val: number | string): string => {
  const isFalsy = val === null || val === undefined || val === '';
  if (isFalsy) {
    return '';
  }
  return val === 0 ? '제한 없음' : `결제 시 최소 ${numberWithCommas(val)}P 부터 사용`;
};

const PointAmountSetting = () => {
  const location = useLocation();
  const storeStatus = location?.state?.storeStatus || ''; // 수정 페이지에서 받아오는 select value
  const paramStatus = location?.state?.paramStatus || ''; // 수정 페이지에서 받아오는 param
  const [detail, setDetail] = useState<minAmountType>({
    minUseAmount: '',
  });
  const [currentStoreID, setCurrentStoreID] = useState(0);
  const [pomiStore, setPomiStore] = useState<PomiStoreListType[] | null>(null);
  const navigate = useNavigate();
  const currentStoreRef = useRef<string | number>();
  const currentSiteIDRef = useRef<string | number>();
  const currentNameRef = useRef<string | number>();
  const [loading, setLoading] = useState<boolean>(false);

  // 매장 전체 텍스트에서 매장번호만 가져오기
  const extractNumber = (str: string) => {
    const regex = /\[(\d+)\]/;
    const match = str.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  };

  const params: pointMinAmountType = {
    storeName: String(currentNameRef.current),
    siteID: Number(currentSiteIDRef.current),
  };

  const handleStoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedValue = selectedOption.value;
    const selectedID = selectedOption.dataset.id;
    const selectName = selectedOption.dataset.name;
    currentStoreRef.current = e.target.value;
    currentSiteIDRef.current = selectedID;
    currentNameRef.current = selectName;

    const params: pointMinAmountType = {
      storeName: String(selectName),
      siteID: Number(selectedID),
    };
    getPointMinAmount(Number(extractNumber(selectedValue)), params);
  };

  const getPointMinAmount = async (storeID: number, params: pointMinAmountType) => {
    const { result, data } = await CallGetPointMinAmount(storeID, params);
    if (result.code === 1) {
      setDetail(data);
      setCurrentStoreID(storeID);
      setLoading(false);
    } else {
      console.log('실패: code =', result.code);
      setLoading(false);
    }
  };
  const getPomiStores = async () => {
    setLoading(true);
    const { result, data } = await CallGetPomiStores();
    if (result.code === 1 && data) {
      setPomiStore(data);
      if (storeStatus) {
        // paramStatus(siteID,storeName)가 도중 불가피하게 사라졌을 경우 메인으로 보냄
        if (!paramStatus) {
          window.location.replace(HOST_BIZ);
          return;
        }
        getPointMinAmount(Number(extractNumber(storeStatus)), paramStatus);
        currentStoreRef.current = storeStatus;
        navigate(location.pathname, { replace: true }); // location.state 초기화
      } else {
        const firstStore = data[0];
        const extractedStoreID = Number(firstStore.ID);
        const firstParams: pointMinAmountType = {
          storeName: firstStore.name,
          siteID: Number(firstStore.siteID),
        };
        getPointMinAmount(extractedStoreID, firstParams);
        currentStoreRef.current = `[${StoreStatusKo[Number(firstStore.status)]}] [${firstStore.ID}] ${firstStore.name}`;
        currentSiteIDRef.current = firstStore.siteID;
        currentSiteIDRef.current = firstStore.siteID;
        currentNameRef.current = firstStore.name;
      }
    } else {
      console.log('실패: code =', result.code, result.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPomiStores();
  }, []);

  return (
    <div className="pointPageWrap amount">
      <p className="notiPlus">※ 위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.</p>
      <div className="searchArea">
        <select onChange={(e) => handleStoreChange(e)} value={storeStatus || currentStoreRef.current}>
          {pomiStore &&
            pomiStore.map((v, idx) => {
              const { ID, name, status, siteID } = v;
              return (
                <option
                  key={`${ID}-${idx}`}
                  value={`[${StoreStatusKo[Number(status)]}] [${ID}] ${name}`}
                  data-id={siteID}
                  data-name={name}
                >
                  [{StoreStatusKo[Number(status)]}] [{ID}] {name}
                </option>
              );
            })}
        </select>
      </div>
      {pomiStore && (
        <>
          <div className="detailArea">
            <dl>
              <dt>최소 사용 금액</dt>
              <dd>{getInitData(detail.minUseAmount)}</dd>
            </dl>
          </div>
          <div className="fixedBtnGroup">
            <button
              type="button"
              id="btnSubmit"
              className="btnDone"
              onClick={() =>
                navigate(`/point/amount/${currentStoreID}`, {
                  state: { currentStoreRef, params },
                })
              }
            >
              수정
            </button>
          </div>
        </>
      )}
      <Spinner show={loading} />
    </div>
  );
};

export default PointAmountSetting;
