import Spinner from '@src/components/molecules/Spinner';
import Toast from '@src/components/molecules/Toast';
import { AWS_IMAGE_URL } from '@src/library/Constants';
import { extractNumber } from '@src/library/Utils';
import { bizStorage } from '@src/library/bizStorage';
import {
  CallPostStoreQR,
  CallGetStoreTablesCount,
  CallPostStoreTablesCount,
} from '@src/library/request/callTableOrder';
import { toastState } from '@src/recoil/toast';
import { StoreList } from '@src/types/mainType';
import { StoreStatusKo } from '@src/types/pointType';
import { StorageStoreListType } from '@src/types/storageType';
import { TABLEORDER_KEYS } from '@src/types/tableOrderType';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

export const noticeText = (
  <>
    <p>입력한 테이블 개수만큼 테이블별 QR코드가 생성됩니다.</p>
    <p>QR코드 생성 시 테이블 번호가 표기된 기본 템플릿으로 생성되며, 템플릿은 변경 불가합니다.</p>
    <p>테이블 추가 시 마지막으로 생성되어 있는 테이블의 다음 번호로 하여 순차적으로 생성됩니다</p>
    <p>
      테이블 주문 사용 설정은 [파트너스] - [테이블 주문 관리] - [테이블 주문 설정] 또는 [주문접수프로그램/APP] - [테이블
      Tab] 최초 진입 시 테이블 주문 이용 가능하도록 설정할 수 있습니다.
    </p>
    <p>
      테이블 생성 후 즉시 QR코드 일괄 또는 개별 다운로드 가능합니다. 단, 저장 버튼을 누르지 않으면 생성된 테이블 정보가
      삭제되니, 테이블 생성 후 반드시 저장해 주세요.
    </p>
  </>
);
export const selectOptions = (data: StoreList[]) => {
  if (!data) return null;
  return data.map((v, idx) => {
    const { ID, name, status } = v;
    return (
      <option key={`${ID}-${idx}`} value={`[${StoreStatusKo[Number(status)]}] [${ID}] ${name}`}>
        [{StoreStatusKo[Number(status)]}] [{ID}] {name}
      </option>
    );
  });
};

let bulkCount = 0; // 테이블 일괄생성 개수

export const TableList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectStore = '', count = 0 } = location?.state || {};
  const storeList = bizStorage.getItem<StorageStoreListType>('storeList');

  const [currentStoreID, setCurrentStoreID] = useState(0);
  const [tables, setTables] = useState<Array<string>>([]);
  const [tableCount, setTableCount] = useState(0); // 매장 original 테이블 수
  const [showTooltip, setShowTooltip] = useState(false);

  const currentStoreRef = useRef<string | number>();
  const tooltipRef = useRef(null);

  const setToast = useSetRecoilState(toastState);
  const isPopup = useRef(location.search.includes('isPopup')); // 주접프 새창 오픈 유무

  const queryClient = useQueryClient();

  const {
    isError,
    isLoading,
    data: listData,
  } = useQuery([TABLEORDER_KEYS.LIST, currentStoreID], () => CallGetStoreTablesCount(currentStoreID), {
    enabled: !!currentStoreID && !count,
  });

  const { mutate: downloadMutate } = useMutation(
    (param: Array<string>) => CallPostStoreQR(currentStoreID, { tables: param }),
    {
      onSuccess: (response) => {
        alert(response.result.message);
      },
    },
  );

  const { mutate: saveMutate } = useMutation(
    (cnt: number) => CallPostStoreTablesCount(currentStoreID, { tableCount: cnt }),
    {
      onSuccess: (response) => {
        const { result } = response;
        if (result.code !== 1) {
          alert(result.message);
          return;
        }
        setToast({
          isOpen: true,
          message: '저장되었습니다.',
        });
        queryClient.invalidateQueries([TABLEORDER_KEYS.LIST, Number(currentStoreID)]);
      },
    },
  );

  const handleStoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setCurrentStoreID(extractNumber(selectedValue));
    currentStoreRef.current = selectedValue;
  };

  const renderTableList = useCallback(() => {
    if (!tables.length) return <p className="noListBox">추가된 테이블이 없습니다.</p>;
    return (
      <div className="qr-list">
        {tables.map((item, idx) => {
          return (
            <div key={`QR-table-${idx}`} className="qr-table">
              <p className="table-name">{item}</p>
              <button type="button" className="active" onClick={() => downloadMutate([item])}>
                QR코드 다운로드
                <img src={`${AWS_IMAGE_URL}/icon/icon-table-download-s@2x.png`} width={11} alt="다운로드" />
              </button>
            </div>
          );
        })}
      </div>
    );
  }, [tables]);

  const onAddTable = () => {
    if (tables.length === 30) {
      setToast({
        isOpen: true,
        message: '최대 30개까지 추가 가능합니다.',
      });
      return;
    }

    const isBulkCreate =
      tables.length === 0 &&
      window.confirm('테이블을 일괄 생성 하시겠습니까?\n취소 버튼 클릭 시 테이블 1개씩 개별로 생성됩니다.');
    if (isBulkCreate) {
      navigate(`/table/list/add/${currentStoreID}${isPopup.current ? '?isPopup' : ''}`, {
        state: currentStoreRef.current,
      });
    } else {
      // 테이블 1개씩 추가
      setTables(tables.concat(`테이블-${tables.length + 1}`));
    }
  };

  const onDeleteTable = () => {
    setTables(tables.slice(0, -1));
  };

  const createTables = (num: number) => {
    const list = Array.from(Array(num), (_, idx) => `테이블-${idx + 1}`);
    setTables(list);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current && !(tooltipRef.current as HTMLElement).contains(event?.target as HTMLElement)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (listData) {
      const { result, data } = listData;
      if (result.code !== 1) {
        alert(result.message);
        return;
      }
      setTableCount(Number(data.tableCount));
      if (bulkCount) {
        createTables(bulkCount);
        bulkCount = 0;
      } else {
        createTables(Number(data.tableCount));
      }
    }
  }, [listData]);

  useEffect(() => {
    const storeID = selectStore ? extractNumber(selectStore) : Number(storeList[0]?.ID);
    setCurrentStoreID(storeID);

    if (selectStore) {
      if (count) bulkCount = count;
      currentStoreRef.current = selectStore;
      navigate(location.pathname, { replace: true }); // location.state 초기화
    } else {
      currentStoreRef.current = `[${StoreStatusKo[Number(storeList[0]?.status)]}] [${storeID}] ${storeList[0]?.name}`;
    }
  }, []);

  if (isError || !storeList) return <Spinner />;

  return (
    <div className="table-page-wrap">
      <div className="tooltip-wrapper">
        <button
          type="button"
          className="tooltip-icon"
          onClick={(e) => {
            e.preventDefault();
            setShowTooltip(true);
          }}
        >
          <img src={`${AWS_IMAGE_URL}/icon/icon-table-info@2x.png`} width={20} alt="툴팁" />
        </button>
        {showTooltip && (
          <div className="tooltip-content notice" ref={tooltipRef}>
            <button
              type="button"
              className="close-icon"
              onClick={(e) => {
                e.preventDefault();
                setShowTooltip(false);
              }}
            >
              <img src={`${AWS_IMAGE_URL}/icon/pc-btn-x-pop-s.png`} alt="닫기" />
            </button>
            {noticeText}
          </div>
        )}
      </div>
      <div className="searchArea">
        <select onChange={(e) => handleStoreChange(e)} value={selectStore || currentStoreRef.current}>
          {selectOptions(storeList)}
        </select>
        <div className="tabSortBox">
          <button
            type="button"
            onClick={() => downloadMutate(tables)}
            className={`${tables.length > 0 ? 'active' : ''}`}
          >
            QR코드 일괄 다운로드
            <img
              src={`${AWS_IMAGE_URL}/icon/icon-table-download${tables.length ? '' : '-dim'}@2x.png`}
              width={14}
              alt="다운로드"
            />
          </button>
        </div>
      </div>
      <div className="table-list-wrap">{renderTableList()}</div>
      <div className="fixed-bottom-area">
        <span className="title">테이블 생성</span>
        <div className="btn-area">
          <button type="button" onClick={onDeleteTable}>
            <img src={`${AWS_IMAGE_URL}/image/btn-count-minus.svg`} alt="삭제" />
          </button>
          <span className={tables.length ? '' : 'txt-grey'}>{tables.length}개</span>
          <button type="button" onClick={onAddTable}>
            <img src={`${AWS_IMAGE_URL}/image/btn-count-plus.svg`} alt="추가" />
          </button>
        </div>
      </div>
      <div className="fixedBtnGroup">
        <button
          type="button"
          id="btnSubmit"
          className="btnDone"
          disabled={tables.length === tableCount}
          onClick={() => saveMutate(tables.length)}
        >
          저장
        </button>
      </div>
      <Spinner show={isLoading} />
      <Toast />
    </div>
  );
};
