import { StoreTableDetailType, StoreTablePutType } from '@src/types/tableOrderType';
import { queryRequest } from '../queryAPI';

/*
  테이블 주문 설정
*/
export interface StoreTableDetail {
  storeTableDetail: StoreTableDetailType;
}
// 조회
export const CallGetStoreTable = (storeID: number) =>
  queryRequest<StoreTableDetail>({ url: `/biz/stores/${storeID}/table`, method: 'get' });

// 등록
export const CallPostStoreTable = (storeID: string, param: StoreTablePutType) =>
  queryRequest({ url: `/biz/stores/${storeID}/table`, method: 'post', data: param });

// 수정
export const CallPutStoreTable = (storeID: string, param: StoreTablePutType) =>
  queryRequest({ url: `/biz/stores/${storeID}/table`, method: 'put', data: param });

/*
  테이블 관리 (QR)
*/
// 테이블 개수 조회
export const CallGetStoreTablesCount = (storeID: number) =>
  queryRequest<{ tableCount: number }>({ url: `/biz/stores/${storeID}/tables`, method: 'get' });

// 저장
export const CallPostStoreTablesCount = (storeID: number, param: { tableCount: number }) =>
  queryRequest({ url: `/biz/stores/${storeID}/tables`, method: 'post', data: param });

// QR 다운로드
export const CallPostStoreQR = (storeID: number, param: { tables: Array<string> }) =>
  queryRequest({ url: `/biz/stores/${storeID}/qr-codes`, method: 'postJson', data: param });
