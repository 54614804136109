import React, { ChangeEvent, useEffect, useRef } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CommonRadioButton from '@components/molecules/radioButton/RadioButton';
import NumberCommaInput from '@components/molecules/input/NumberCommaInput';
import { numberWithCommasToNum } from '@library/Utils';
import DateTimeInputRange from '@components/molecules/datepicker/DateTimeInputRange';
import TimeInputRange from '@components/molecules/input/TimeInputRange';
import {
  AccumulatePriceType,
  AccumulateType,
  EndDateEmpty,
  InitPointDataType,
  ModeType,
  WeekStatusOptions,
} from '@type/pointType';

export interface AddPropsType {
  data?: InitPointDataType;
  formControl: UseFormReturn<FieldValues, unknown>;
  onCheckValidate?: () => boolean;
  onClickSave?: () => void;
  mode?: (typeof ModeType)[keyof typeof ModeType];
  onClickNextBtn?: () => void;
}

const getConvertBinaryDay = (binary: string) => {
  const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
  const result: string[] = [];
  for (let i = 0; i < binary.length; i += 1) {
    if (binary[i] === '1') {
      result.push(weekdays[i]);
    }
  }
  return result;
};

const PointOnlineAddBasic: React.FC<AddPropsType> = (props) => {
  const { formControl, mode = '', onClickNextBtn } = props;
  const navigate = useNavigate();
  const { eventID = '' } = useParams();

  const { getValues, setValue, watch, register } = formControl;
  const isCheckEndDate = getValues('endDate').split('-')[0] === '9999';
  const divRef = useRef(null);

  const onChangeRadio = (e: ChangeEvent<HTMLInputElement>, val?: number | string, resetFiled?: string) => {
    const { name } = e.target as HTMLInputElement;
    setValue(name, val);
    if (resetFiled) {
      setValue(resetFiled, null);
    }
  };

  const onChangeNumberInput = (e: React.ChangeEvent<HTMLInputElement>, id: string, value: string) => {
    const { name } = e.target;
    setValue(name, numberWithCommasToNum(value));
  };

  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const check = e.target.checked;
    if (check) {
      setValue('endDate', EndDateEmpty);
    } else {
      setValue('endDate', getValues('beginDate'));
    }
  };

  // 적용 주기 요일 선택
  const onChangeDay = (value: string, day: string) => {
    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
    const index = weekdays.indexOf(day);
    if (!value) {
      if (index === -1) {
        return '00000000';
      }
      const newValue = '00000000'.split('');
      newValue[index] = '1';
      setValue('day', newValue.join(''));
    } else {
      if (index === -1) {
        return value;
      }
      const newValue = value.split('');
      newValue[index] = newValue[index] === '0' ? '1' : '0';
      setValue('day', newValue.join(''));
    }
    return null;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (divRef.current) {
      divRef.current.blur();
    }
  }, []);

  return (
    <div className="pointPageWrap online">
      <div className="stepTitlebox">
        <p>포인트 조건 설정</p>
        <span>
          <em>1</em> &nbsp;/&nbsp; 2
        </span>
      </div>
      <div className="detailFormArea">
        {/* 포인트 명 */}
        <div className="formItem">
          <span className="title">포인트명</span>
          <input type="text" placeholder="포인트명을 입력해주세요." name="name" {...register('name')} />
        </div>
        {/* 적립 방법 */}
        <div className="formItem">
          <span className="title">적립 방법</span>
          {/* 정률 */}
          <div className="radioType">
            <CommonRadioButton
              name="accumulateType"
              id="accumulateType1"
              label="결제 금액의"
              value={AccumulateType.percent}
              check={getValues('accumulateType') === AccumulateType.percent}
              onChangeHandler={(e) => onChangeRadio(e, AccumulateType.percent, 'reserveAmount')}
            />
            <div className="inputLabel">
              <NumberCommaInput
                placeholder="1"
                name="reserveRate"
                value={watch('reserveRate')}
                onChange={onChangeNumberInput}
                disabled={watch('accumulateType') !== AccumulateType.percent}
                max={100}
              />
              <span>% 적립</span>
            </div>
          </div>
          {/* 정액 */}
          <div className="radioType">
            <CommonRadioButton
              name="accumulateType"
              id="accumulateType2"
              label="결제 금액과 상관없이"
              value={AccumulateType.price}
              check={watch('accumulateType') === AccumulateType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulateType.price, 'reserveRate')}
            />
            <div className="inputLabel">
              <NumberCommaInput
                placeholder="1"
                name="reserveAmount"
                value={watch('reserveAmount')}
                onChange={onChangeNumberInput}
                disabled={watch('accumulateType') !== AccumulateType.price}
              />
              <span>P 적립</span>
            </div>
          </div>
        </div>
        {/* 최대 적립 금액 */}
        <div className="formItem">
          <span className="title">최대 적립 금액</span>
          <div className="radioType">
            <CommonRadioButton
              name="maxPriceType"
              id="maxPriceType1"
              label="제한 없음"
              value={AccumulatePriceType.none}
              check={watch('maxPriceType') === AccumulatePriceType.none}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.none, 'maxReserveAmount')}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="maxPriceType"
              id="maxPriceType2"
              label="최대"
              value={AccumulatePriceType.price}
              check={watch('maxPriceType') === AccumulatePriceType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.price)}
            />
            <div className="inputLabel">
              <NumberCommaInput
                placeholder="1"
                name="maxReserveAmount"
                value={watch('maxReserveAmount')}
                onChange={onChangeNumberInput}
                disabled={watch('maxPriceType') !== AccumulatePriceType.price}
              />
              <span>P 적립</span>
            </div>
          </div>
        </div>
        {/* 최소 결제 금액 */}
        <div className="formItem">
          <span className="title">최소 결제 금액</span>
          <div className="radioType">
            <CommonRadioButton
              name="minPriceType"
              id="minPriceType1"
              label="제한 없음"
              value={AccumulatePriceType.none}
              check={watch('minPriceType') === AccumulatePriceType.none}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.none, 'minOrderAmount')}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="minPriceType"
              id="minPriceType2"
              label="최소"
              value={AccumulatePriceType.price}
              check={watch('minPriceType') === AccumulatePriceType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.price)}
            />
            <div className="inputLabel">
              <NumberCommaInput
                placeholder="1"
                name="minOrderAmount"
                value={watch('minOrderAmount')}
                onChange={onChangeNumberInput}
                disabled={watch('minPriceType') !== AccumulatePriceType.price}
              />
              <span>원 이상 결제 시 적립</span>
            </div>
          </div>
        </div>
        {/* 적립 기간 */}
        <div className="formItem">
          <span className="title">적립기간</span>
          <div className="dateType">
            <DateTimeInputRange
              beginDate={watch('beginDate')}
              endDate={watch('endDate')}
              beginName="beginDate"
              endName="endDate"
              isEndCheckBox
              checkValue={isCheckEndDate}
              onChangeCheckBox={onChangeCheckBox}
              onChangeDateTime={(name, value) => setValue(name, value)}
            />
          </div>
        </div>
        {/* 적용 주기 */}
        <div className="formItem">
          <span className="title">적용 주기</span>
          <div className="dateWeek formUnit">
            <span>적립기간 내</span>
            <select name="day" onChange={(e) => setValue('week', Number(e.target.value))} value={getValues('week')}>
              {WeekStatusOptions.map((op, index) => {
                return (
                  <option key={`${op}_${index}`} value={op.value}>
                    {op.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="dateRange formUnit">
            {['월', '화', '수', '목', '금', '토', '일'].map((val, index) => {
              return (
                <button
                  type="button"
                  onClick={() => onChangeDay(watch('day'), val)}
                  className={`${watch('day') && getConvertBinaryDay(watch('day')).includes(val) && 'active'} `}
                  key={`${val}_${index}`}
                  id="dateWeek"
                  disabled={false}
                >
                  {val}
                </button>
              );
            })}
            <span>마다</span>
          </div>
          <div className="formUnit">
            <TimeInputRange
              beginName="beginTime"
              endName="endTime"
              beginTime={watch('beginTime')}
              endTime={watch('endTime')}
              onChangeDateTime={(name: string, value: string) => {
                setValue(name, value);
              }}
              hour24
            />
          </div>
        </div>
        {/* 유효기간 */}
        <div className="formItem">
          <span className="title">유효기간</span>
          <div className="radioType">
            <CommonRadioButton
              name="validTermType"
              id="validTermType1"
              label="제한 없음"
              value={AccumulatePriceType.none}
              check={watch('validTermType') === AccumulatePriceType.none}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.none, 'pointValidTerm')}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="validTermType"
              id="validTermType2"
              label="적립일로 부터"
              value={AccumulatePriceType.price}
              check={watch('validTermType') === AccumulatePriceType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.price)}
            />
            <div className="inputLabel">
              <NumberCommaInput
                placeholder="1"
                name="pointValidTerm"
                value={watch('pointValidTerm')}
                onChange={onChangeNumberInput}
                disabled={watch('validTermType') !== AccumulatePriceType.price}
              />
              <span>일</span>
            </div>
          </div>
        </div>
        <div className="fixedBtnGroup">
          {mode === ModeType.edit && (
            <button
              type="button"
              id="btnSubmit"
              className="btnEdit"
              onClick={() => {
                if (window.confirm('페이지를 벗어나시겠습니까?\n입력하신 내용은 저장되지 않습니다.')) {
                  navigate(`/point/online/detail/${eventID}`);
                }
              }}
            >
              취소
            </button>
          )}
          <button type="button" id="btnSubmit" className="btnDone" onClick={onClickNextBtn}>
            다음
          </button>
        </div>
      </div>
    </div>
  );
};

export default PointOnlineAddBasic;
