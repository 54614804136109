import moment from 'moment';
import { DateRangeType } from './dateType';

export type DateHourMin = {
  date: string;
  hour: string;
  min: string;
};

export const divideDateHourMin = (dateTime: string): DateHourMin => {
  if (!dateTime) return { date: '', hour: '', min: '' };

  const [date, time] = dateTime.split(' ');
  const [hour, min] = time.split(':');

  return {
    date,
    hour,
    min,
  };
};

export const mergeDateTime = ({ date, hour, min }: DateHourMin) => {
  return `${date} ${hour}:${min}`;
};

// date: YYYY-MM-DD HH:mm:ss
export const extractSec = (dateTime: string | null): string => {
  if (!dateTime) return '';
  return dateTime.slice(0, -3);
};

export const isValidDate = (begin: string, end: string) => {
  if (!begin || !end) {
    console.log('[isValidDate] begin:', begin, ' end:', end, ' 중 null 이 있습니다.');
    return false;
  }
  // endDate 는 beginDate 보다 뒤에 있어야 함
  return moment(end).isAfter(begin);
};

export const defaultDateRangeDate = (today: Date, type: DateRangeType) => {
  switch (type) {
    case DateRangeType.Today:
      return { s: today, e: today };
    case DateRangeType.Yesterday:
      return {
        s: moment().subtract(1, 'days').toDate(),
        e: moment().subtract(1, 'days').toDate(),
      };
    case DateRangeType.RecentSevenDays:
      return {
        s: moment().subtract(1, 'week').toDate(),
        e: moment().subtract(0, 'week').toDate(),
      };
    case DateRangeType.RecentSevenPrevDays:
      return {
        s: moment().subtract(1, 'week').subtract(1, 'day').toDate(),
        e: moment().toDate(),
      };
    case DateRangeType.RecentOneMonth:
      return {
        s: moment().subtract(1, 'month').toDate(),
        e: moment().subtract(0, 'month').toDate(),
      };
    case DateRangeType.RecentOnePrevMonth:
      return {
        s: moment().subtract(1, 'month').subtract(1, 'day').toDate(),
        e: moment().toDate(),
      };
    case DateRangeType.RecentThreeMonths:
      return {
        s: moment().subtract(3, 'month').toDate(),
        e: moment().subtract(0, 'month').toDate(),
      };
    case DateRangeType.ThisMonth:
      return {
        s: moment().subtract(1, 'day').startOf('month').toDate(),
        e: moment().subtract(1, 'day').toDate(),
      };
    case DateRangeType.LastMonth:
      return {
        s: moment().subtract(1, 'day').subtract(1, 'month').startOf('month').toDate(),
        e: moment().subtract(1, 'day').subtract(1, 'month').endOf('month').toDate(),
      };
    default:
      return { s: null, e: null };
  }
};

// Date 형식을 yyyy-mm-dd 형식으로 변경한다.
export const dateToYYYYMMDD = (date: Date) => {
  // date 에 값 안들어오면 "" 리턴한다.
  if (!date) return '';

  // Date 형식이 들어와야 한다.
  if (typeof date.getMonth !== 'function') return '';

  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

/**
 * 날짜 등록 시 초기 값 셋팅을 위해 오늘날짜 및 시간에서 분단위 이후 10분단위로 출력 (초는 0으로)
 */
export const getTodayDateTime = (): string => {
  const today = moment();
  today.minutes(Math.floor(today.minutes() / 10) * 10).add(10, 'minutes');
  return today.format('yyyy-MM-DD HH:mm');
};

export const dateFormatYMD = (date: string | Date): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const getTodayTime = () => {
  const today = moment();
  today.minutes(Math.floor(today.minutes() / 10) * 10).add(10, 'minutes');
  return today.format('HH:mm:00');
};

export const isValidTime = (startTime: string, endTime: string): boolean => {
  const start = moment(startTime, 'HH:mm:ss');
  const end = moment(endTime, 'HH:mm:ss');

  return start.isBefore(end);
};
