import { getTodayDateTime } from '@type/date/dateHelper';

// 포인트 상태값 ( 0: 저장, 3: 발급대기, 1: 발급중, 4: 발급중단, 5: 발급정료)
export const PointStatus = {
  all: 9,
  save: 0,
  wait: 3,
  publish: 1,
  stop: 4,
  finish: 5,
};

export const PointUseStatus = {
  accumulate: 10, // 적립
  accumulateCancel: 11, // 적립취소
  use: 20, // 사용
  cancel: 21, // 사용 취소
  finish: 40, // 소멸
  offAccumulate: 50, // 오프라인포인트 적립
  offAccumulateCancel: 51, // 오프라인포인트 적립취소
  offCancel: 61, // 오프라인포인트 사용취소
  offUse: 60, // 오프라인포인트 사용
};

export const PointStatusKo = {
  [PointStatus.all]: '전체 상태',
  [PointStatus.save]: '저장',
  [PointStatus.wait]: '발급대기',
  [PointStatus.publish]: '발급중',
  [PointStatus.stop]: '발급중단',
  [PointStatus.finish]: '발급종료',
};
export const PointUseStatusEn = {
  [PointUseStatus.accumulate]: 'publish',
  [PointUseStatus.accumulateCancel]: 'stop',
  [PointUseStatus.use]: 'save',
  [PointUseStatus.cancel]: 'stop',
  [PointUseStatus.finish]: 'stop',
  [PointUseStatus.offAccumulate]: 'publish',
  [PointUseStatus.offAccumulateCancel]: 'stop',
  [PointUseStatus.offCancel]: 'stop',
  [PointUseStatus.offUse]: 'save',
};

export const PointStatusEn = {
  [PointStatus.all]: 'all',
  [PointStatus.save]: 'save',
  [PointStatus.wait]: 'wait',
  [PointStatus.publish]: 'publish',
  [PointStatus.stop]: 'stop',
  [PointStatus.finish]: 'stop',
};

export const pointTypeOptions = [
  { name: PointStatusKo[PointStatus.all], value: PointStatus.all },
  { name: PointStatusKo[PointStatus.save], value: PointStatus.save },
  { name: PointStatusKo[PointStatus.wait], value: PointStatus.wait },
  { name: PointStatusKo[PointStatus.publish], value: PointStatus.publish },
  { name: PointStatusKo[PointStatus.stop], value: PointStatus.stop },
  { name: PointStatusKo[PointStatus.finish], value: PointStatus.finish },
];

export type InitPointDataType = {
  name: string;
  beginDate?: string;
  endDate?: string;
  reserveRate?: string | number;
  reserveAmount?: number;
  minOrderAmount?: number;
  maxReserveAmount?: number;
  pointCode?: number;
  pointValidTerm: number;
  week?: number;
  day?: string;
  beginTime?: string;
  endTime?: string;
  storeIDs: string[];
  // 화면 세팅용
  accumulateType?: string;
  maxPriceType?: string;
  minPriceType?: string;
  validTermType?: string;
};

// 적립방법 (1: 정률, 2: 정액)
export const AccumulateType = {
  percent: 'percent',
  price: 'price',
};

export const AccumulatePriceType = {
  none: 'none',
  price: 'nonePrice',
};

export const EndDateEmpty = '9999-12-31 23:59'; // 등록페에지의 종료일 없음인 경우(POST)
export const InitPointData: InitPointDataType = {
  name: null,
  beginDate: getTodayDateTime(), // 2019-01-10 00:00
  endDate: getTodayDateTime(), // 2019-01-10 00:00
  reserveRate: null,
  reserveAmount: null,
  minOrderAmount: null,
  maxReserveAmount: null,
  pointCode: null,
  pointValidTerm: null,
  week: 0,
  day: '1111111',
  beginTime: '00:00:00', // 00:00:00
  endTime: '24:00:00', // 00:00:00
  storeIDs: [],
  // 화면 세팅용
  accumulateType: AccumulateType.percent,
  maxPriceType: AccumulatePriceType.none,
  minPriceType: AccumulatePriceType.none,
  validTermType: AccumulatePriceType.none,
};

export const WeekStatusOptions = [
  { name: '매주', value: 0 },
  { name: '매월 첫째', value: 1 },
  { name: '매월 둘째', value: 2 },
  { name: '매월 셋째', value: 3 },
  { name: '매월 넷째', value: 4 },
  { name: '매월 다섯째', value: 5 },
  { name: '매월 마지막', value: 6 },
];

export type OfflineDetailType = {
  storeID: number;
  name: string;
  reserveText: string;
  reserveRate: number;
  reserveAmount: number;
  pointValidTerm: number;
  maxReserveAmount: number;
  minUseAmount: number;
};

export type OfflinePutType = {
  name: string;
  reserveRate: number | string;
  reserveAmount: number | string;
  pointValidTerm: number | string;
  maxReserveAmount: number | string;
  // 화면 세팅용
  accumulateType?: string;
  maxPriceType?: string;
  validTermType?: string;
};

export type PutMinAMountType = {
  minUseAmount: number | string;
  minPriceType: string;
};

export const OfflineDetailInit: OfflineDetailType = {
  storeID: 0,
  name: '',
  reserveText: '',
  reserveRate: 0,
  reserveAmount: 0,
  pointValidTerm: 0,
  maxReserveAmount: 0,
  minUseAmount: 0,
};

export interface StroeListType {
  ID: string;
  name: string;
  status: string;
  statusText: string;
  isDelivery: string;
  isPickup: string;
  mainImageUrl: string;
}

export type GetPointHistoryType = {
  page?: number;
  limit?: number;
  executeType?: number;
  storeID?: number;
  executeAt?: number | string;
  searchType?: number;
  searchValue?: string;
  beginDate?: string | null;
  endDate?: string | null;
};

export const InitPontHistoryType: GetPointHistoryType = {
  page: 0,
  limit: 0,
  executeType: 0,
  storeID: 0,
  executeAt: 0,
  searchType: 0,
  searchValue: '',
  beginDate: null,
  endDate: null,
};

export interface PointListType {
  eventID: string;
  name: string;
  status: number;
  storeName: string;
  reserveRate: string;
  reserveAmount: string;
  beginDate: Date;
  endDate: Date;
  created: Date;
}

export interface PointListDetailType {
  event: PointListDetailEvent;
  stores?: PointListDetailList[];
}

export interface PointListDetailEvent {
  reserveText: string;
  reserveDateTime: string;
  ID: string;
  name: string;
  maxReserveAmount: number;
  minOrderAmount: number;
  beginDate: string;
  endDate: string;
  pointValidTerm: number;
  created: string;
  status: string;
}

export interface PointListDetailList {
  ID: number;
  name: string;
}

export interface PointHistoryListType {
  total_items: number;
  items: PointHistoryItemType[];
  total: PointHistoryTotalType;
}

export interface PointHistoryTotalType {
  totalAmount: string;
  usedAmount: string;
  remainAmount: string;
}
export interface PointHistoryItemType {
  orderNo: string;
  storeID: string;
  storeName: number;
  created: number;
  executeType: number;
  executeTypeText: string;
  amount: number;
  executeAtTitle: string;
  executeAt: string;
  expireType?: string;
}

export interface PointOfflineType {
  eventID: number;
  storeID: number;
  name: string;
  reserveRate: number;
  reserveAmount: number;
  pointValidTerm: number;
  maxReserveAmount: number;
  minUseAmount: number;
  reserveText: string;
}
export interface PointOnlineType {
  eventID: string;
  name: string;
  status: number;
  storeName: string;
  reserveRate: string;
  reserveAmount: number;
  beginDate: string;
  endDate: string;
  created: string;
}

export interface PomiStoreListType {
  ID: string;
  name: string;
  status: number;
  siteID: string;
}

// 0:정보입력중, 1:오픈심사대기, 2:메뉴심사대기, 3:심사반려, 4:판매중, 5:판매중지
export const StoreStatus = {
  input: 0,
  open: 1,
  menu: 2,
  refusal: 3,
  sale: 4,
  stop: 5,
};
export type StoreStatus = (typeof StoreStatus)[keyof typeof StoreStatus];

export const StoreStatusKo = {
  [StoreStatus.input]: '판매중지',
  [StoreStatus.open]: '오픈심사대기',
  [StoreStatus.menu]: '메뉴심사대기',
  [StoreStatus.refusal]: '심사반려',
  [StoreStatus.sale]: '판매중',
  [StoreStatus.stop]: '판매중지',
};

export const ModeType = {
  new: 'new',
  edit: 'edit',
  detail: 'detail',
};
export type ModeType = (typeof ModeType)[keyof typeof ModeType];
