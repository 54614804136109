import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AWS_IMAGE_URL } from '@src/library/Constants';
import { isMobileDevice } from '@src/library/Utils';

const PosProgram = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') || 'program'; // program, app
  const tab = searchParams.get('tab') || 'download'; // download, guid
  const defalutSubTab = tab === 'download' ? 'app' : 'login';
  const subTab = searchParams.get('subTab') || defalutSubTab; // login, save
  const downRef = useRef(null);

  const downUrl = 'https://pomipoint.page.link/download';

  const handleTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setSearchParams({ type, tab: value });
  };

  const handleSubTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    window.scrollTo({
      top: 0,
    });
    const { value } = e.currentTarget;
    setSearchParams({ type, tab, subTab: value });
  };

  const handleDownload = () => {
    downRef.current.click();
  };

  return (
    <>
      <div className="tab-guide">
        <ul>
          <li>
            <button type="button" value="download" data-on={tab === 'download'} onClick={handleTab}>
              <span className="txt-tab">다운로드</span>
            </button>
          </li>
          <li>
            <button type="button" value="guid" data-on={tab === 'guid'} onClick={handleTab}>
              <span className="txt-tab">이용 가이드</span>
            </button>
          </li>
        </ul>
      </div>
      <article data-show={tab === 'download'}>
        <div className="list-guide" role="tabpanel">
          <ul id="tabList" role="tablist">
            <li>
              <button
                type="button"
                value="pc"
                data-on={subTab === 'app'}
                className="link-guide "
                onClick={handleSubTab}
              >
                <span className="txt-tab">APP</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="panel-guide">
          <section className="guide-section">
            <div id="pos-tab-7" className="pos-tab-content on">
              <div className="box-pos-latest">
                <div className="info-pos">
                  <div>
                    <h4 className="tit-pos">매장 APP</h4>
                    <p className="desc-pos">
                      포미포인트 매장 APP에 고객이 휴대폰 번호만 입력하면 포인트가 적립됩니다. <br />
                      간편한 포인트 적립을 통해 많은 고객이 우리 매장의 회원이 됩니다.
                    </p>
                  </div>
                </div>
                <img
                  src={`${AWS_IMAGE_URL}/image/00-popo-storeguide-appdownload@3x.jpg`}
                  alt="포포 프로그램 사용 이미지"
                  className="pullImg"
                />
                {isMobileDevice() ? (
                  <>
                    <button type="button" className="btn-download" onClick={handleDownload}>
                      최신버전 다운로드
                      <span className="icon-download" />
                    </button>
                    <a
                      href={downUrl}
                      className="btn-download-a"
                      ref={downRef}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      최신버전 다운로드
                    </a>
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn-download"
                    onClick={() => {
                      alert('포미포인트 매장 APP은 모바일에서만 다운로드 가능합니다.');
                    }}
                  >
                    최신버전 다운로드 <span className="icon-download" />
                  </button>
                )}
              </div>
            </div>
          </section>
        </div>
      </article>
      <article data-show={tab === 'guid'}>
        <div className="list-guide" role="tabpanel">
          <ul>
            <li>
              <button
                type="button"
                value="login"
                data-on={subTab === 'login'}
                className="link-guide "
                onClick={handleSubTab}
              >
                <span className="txt-tab">로그인</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                value="save"
                data-on={subTab === 'save'}
                className="link-guide "
                onClick={handleSubTab}
              >
                <span className="txt-tab">포인트 적립</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="panel-guide" data-show={subTab === 'login'}>
          <ul className="list-info">
            <li>매장 APP을 설치 후 실행하면 아래와 같은 로그인 화면이 보여집니다.</li>
          </ul>
          <ol className="list-steps">
            <li>
              <strong className="tit-step">
                <em>STEP 1</em> 로그인
              </strong>
              <p className="desc-step">
                위메프오 파트너스 사이트(https://partner.wmpo.co.kr)에서 가입한 아이디와 비밀번호를 입력해주세요.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-storeguide-img-01-1@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 2</em> 로그인 후 매장 APP 실행
              </strong>
              <p className="desc-step">
                로그인과 동시에 포미포인트 매장 APP이 실행됩니다. 휴대폰 번호를 입력하여 포인트 적립을 해보세요.
              </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-storeguide-img-01-2@3x.jpg`} className="img-step" alt="" />
            </li>
          </ol>
        </div>
        <div className="panel-guide" data-show={subTab === 'save'}>
          <ul className="list-info">
            <li>
              사장님 프로그램에서 휴대폰 번호를 고객에게 입력 요청 할 경우 매장 APP에서 고객이 직접 휴대폰 번호를
              입력하여 포인트 적립을 할 수 있습니다.
            </li>
          </ul>
          <ol className="list-steps">
            <li>
              <strong className="tit-step">
                <em>STEP 1</em> 휴대폰 번호 입력
              </strong>
              <p className="desc-step">휴대폰 번호 입력 후 [확인] 버튼을 눌러주세요.</p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-storeguide-img-02-1@3x.jpg`} className="img-step" alt="" />
            </li>
            <li>
              <strong className="tit-step">
                <em>STEP 2</em> 포인트 적립
              </strong>
              <p className="desc-step">휴대폰 번호 입력이 완료되면 입력한 휴대폰 번호로 포인트 적립이 완료됩니다. </p>
              <img src={`${AWS_IMAGE_URL}/image/00-popo-storeguide-img-02-2@3x.jpg`} className="img-step" alt="" />
            </li>
          </ol>
        </div>
      </article>
    </>
  );
};

export default PosProgram;
