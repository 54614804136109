import { atom } from 'recoil';

const toastState = atom({
  key: 'toastState',
  default: {
    isOpen: false,
    message: null,
  },
});

export { toastState };
