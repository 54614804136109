import React, { CSSProperties, ChangeEvent, SyntheticEvent } from 'react';

export type Input = {
  id?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties | undefined;
};

export enum NumberCommaInputFormat {
  float = 'float',
}

export interface InputType extends Input {
  placeholder?: string;
  value: string | number;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  readOnly?: boolean;
  onBlur?: (event: SyntheticEvent, id: any, value: any) => void;
  onFocus?: (event: SyntheticEvent, id: any, value: any) => void;
  onChange?: (event: SyntheticEvent, id: any, value: any) => void;
}

export interface TextInputType extends Input {
  placeholder?: string;
  value: string;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  readOnly?: boolean;
  emojiPrevent?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
}

export interface NumberCommaInputType {
  id?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties | undefined;
  placeholder?: string;
  value: string | number | undefined;
  format?: NumberCommaInputFormat;
  max?: number;
  min?: number;
  maxLength?: number;
  decimalSize?: number;
  readOnly?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  isPermitZero?: boolean;
}

export interface PercentInputType {
  id?: string;
  name: string;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties | undefined;
  placeholder?: string;
  value: number | string | null;
  format?: NumberCommaInputFormat;
  max?: number;
  maxLength?: number;
  decimalSize?: number;
  readOnly?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface ImgFileInputType extends Input {
  width?: any;
  height?: any;
  fixedWidth?: number;
  fixedHeight?: number;
  maxSize?: number;
  defaultValue?: string;
  extensionValue?: string;
  onChange: (id: any, value: any) => void;
}

export type SelectOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
  selected?: boolean;
  hidden?: boolean;
};

export interface SelectInputType extends Input {
  value: string | number;
  multiple?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>, id: string, value: string) => void;
  options: SelectOption[];
}

// 라디오는 name 을 필수로 써야해서 Input extends 안할께요 ㅎㅎ
export interface RadioInputType {
  name: string;
  label: string; // label htmlFor 에는 string | undefined 만 가능하다.
  value: string | number;
  id?: string;
  className?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  style?: CSSProperties | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string | number, value: string | number) => void;
}

export type RadioSearchOption = {
  label: string;
  value: string | number;
  id?: string | number;
  required?: boolean;
  disabled?: boolean;
};

export interface RadioGroupInputType {
  name: string;
  value: string | number;
  id?: string;
  className?: string;
  align?: string;
  options: RadioSearchOption[];
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string | number, value: string | number) => void;
}

export interface CheckboxInputType {
  id?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
  align?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string, values: string | string[] | number[]) => void;
  options: { label: any; value: number; checked: boolean }[];
}

export interface CheckboxButtonGroupType {
  name: string;
  className?: string;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string, values: string | number[]) => void;
  options: { label: string; value: number; checked: boolean; className?: string }[];
}

export interface HexCodeInputType {
  id: string;
  name: string;
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
}

export interface ColorInputType {
  width?: number;
  id: string;
  name: string;
  className?: any;
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, id: string, value: string) => void;
}

export interface DateInputType {
  id: string;
  /*name?: string | null;*/
  value: string;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  placeholder?: string;
  format?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (id: string, value: string) => void;
  onFocus?: () => void;
}

export interface TimeInputType extends Input {
  value: string;
  minuteBy: number;
  max?: boolean;
  maxRangeTime?: string;
  minRangeTime?: string;
  seconds?: boolean;
  disableBefore?: boolean;
  onChange: (event: ChangeEvent<HTMLSelectElement>, id: string, value: string) => void;
}
