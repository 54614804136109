import React, { ChangeEvent } from 'react';

interface RadioButtonProps {
  name: string;
  id: string;
  label?: string;
  check?: boolean;
  value?: string | number;
  defaultChecked?: boolean;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>, val?: string | number) => void;
  disabled?: boolean;
}

const CommonRadioButton = (props: RadioButtonProps) => {
  const { name, id, label, check, value, defaultChecked, onChangeHandler, disabled } = props;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChangeHandler) {
      onChangeHandler(e, value);
    }
  };

  return (
    <label htmlFor={id} className="commonRadioBox">
      <input
        type="radio"
        id={id}
        name={name}
        checked={check}
        value={value}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="icoCheck" />
      <span className="label">{label}</span>
    </label>
  );
};

export default CommonRadioButton;
