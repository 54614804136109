import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { bizStorage } from '@src/library/bizStorage';
import { CallGetToken, CallGetInit } from '@src/library/request/callMain';
import { CLIENTID } from '@src/library/Constants';
import Spinner from '@src/components/molecules/Spinner';

const LoginCheck = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const passedCode = searchParams.get('code');

  const getToken = async () => {
    const { result } = await CallGetToken({
      clientID: CLIENTID,
      grantType: 'authorizationCode',
      code: passedCode,
    });
    if (result.code === 1) {
      const { result, data } = await CallGetInit();
      if (result.code === 1) {
        bizStorage.setItem('menu', data.menuList);

        if (data.isLogin === 1) {
          bizStorage.setItem('company', data.company);
          bizStorage.setItem('storeList', data.storeList);
          bizStorage.setItem('dealList', data.dealList);
        }
        window.location.replace(redirectUrl);
      }
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return <Spinner />;
};

export default LoginCheck;
