import React, { useEffect } from 'react';
import moment from 'moment';
import { DateHourMin, divideDateHourMin } from '@src/types/date/dateHelper';
import DateRangePicker from '@components/molecules/datepicker/DateRangePicker';
import TimeInput from '@components/molecules/input/TimeInput';
import CommonCheckBox from '@components/molecules/checkBox/CheckBox';

type DateTimeInputType = {
  beginName: string;
  endName: string;
  beginDate: string | null;
  endDate: string | null;
  onChangeDateTime: (name: string, value: string) => void;
  disabled?: boolean;
  isEndCheckBox?: boolean;
  checkValue?: boolean;
  onChangeCheckBox?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const DateTimeInput: React.FC<DateTimeInputType> = (props) => {
  const {
    beginName,
    endName,
    beginDate,
    endDate,
    onChangeDateTime = () => {},
    disabled,
    isEndCheckBox = false,
    checkValue = false,
    onChangeCheckBox = () => {},
  } = props;

  const today = new Date();
  const beginDateHourMin: DateHourMin = divideDateHourMin(beginDate || moment(today).format('YYYY-MM-DD HH:mm'));
  const endDateHourMin: DateHourMin = divideDateHourMin(endDate || moment(today).format('YYYY-MM-DD HH:mm'));

  const onBeginChangeDate = (beginDate: Date) => {
    const newDate = moment(beginDate).format('YYYY-MM-DD');
    onChangeDateTime(beginName, `${newDate} ${beginDateHourMin.hour}:${beginDateHourMin.min}`);
  };

  const onBeginClickHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(beginName, `${beginDateHourMin.date} ${value}:${beginDateHourMin.min}`);
  };

  const onBeginClickMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(beginName, `${beginDateHourMin.date} ${beginDateHourMin.hour}:${value}`);
  };

  const onEndChangeDate = (endDate: Date) => {
    const newDate = moment(endDate).format('YYYY-MM-DD');
    onChangeDateTime(endName, `${newDate} ${endDateHourMin.hour}:${endDateHourMin.min}`);
  };

  const onEndClickHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(endName, `${endDateHourMin.date} ${value}:${endDateHourMin.min}`);
  };

  const onEndClickMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(endName, `${endDateHourMin.date} ${endDateHourMin.hour}:${value}`);
  };

  useEffect(() => {
    if (beginDate && endDate && beginDate > endDate) {
      onChangeDateTime(endName, beginDate);
    }
  }, [beginDate, endDate]);

  return (
    <div className="commonDateTimeRange">
      {isEndCheckBox && (
        <CommonCheckBox id="noDate" label="종료일 없음" value={checkValue} onChange={onChangeCheckBox} />
      )}

      <div className="dateWrap">
        <div>
          <DateRangePicker startDate={moment(beginDateHourMin.date).toDate()} onChange={onBeginChangeDate} />
        </div>
        <TimeInput name={beginName} value={beginDateHourMin.hour} onChange={onBeginClickHour} disabled={disabled} />
        :
        <TimeInput name={beginName} value={beginDateHourMin.min} onChange={onBeginClickMin} type="min" />
        <span>부터</span>
      </div>

      <div className="dateWrap">
        <div>
          <DateRangePicker
            startDate={checkValue ? null : moment(endDateHourMin.date).toDate()}
            onChange={onEndChangeDate}
            minStartDate={moment(beginDateHourMin.date).toDate()}
            disabled={checkValue}
            startPlaceholder="종료일"
          />
        </div>
        <TimeInput
          name={endName}
          value={endDateHourMin.hour}
          onChange={onEndClickHour}
          disabled={checkValue}
          isDefaultVal={!checkValue}
          placeholder="시"
        />
        :
        <TimeInput
          name={endName}
          value={endDateHourMin.min}
          onChange={onEndClickMin}
          disabled={checkValue}
          type="min"
          placeholder="분"
          isDefaultVal={!checkValue}
        />
        <span>까지</span>
      </div>
    </div>
  );
};

export default React.memo(DateTimeInput);
