import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CommonRadioButton from '@src/components/molecules/radioButton/RadioButton';
import { AccumulatePriceType, PutMinAMountType } from '@src/types/pointType';
import { CallPostPointMinAmount, CallGetPointMinAmount } from '@src/library/request/callPoint';
import { numberWithCommas } from '@src/library/Utils';
import useBackDetection from '@src/hooks/useBackDetection';

const PointAmountEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { storeID } = useParams();
  const storeStatus = location?.state.currentStoreRef.current;
  const paramStatus = location?.state.params;

  const [searchParam, setSearchParam] = useState<PutMinAMountType>({
    minUseAmount: 0,
    minPriceType: AccumulatePriceType.none,
  });

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>, val?: number | string, resetFiled?: string) => {
    const { name } = e.target as HTMLInputElement;

    setSearchParam({
      ...searchParam,
      [name]: val,
      [resetFiled]: '',
    });
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    let inputVal = value.toString().replace(/[^\d]/g, ''); // 점(.)을 제외한 숫자만 추출

    // 첫 번째 입력 숫자가 0으로 시작하는 경우
    if (inputVal.startsWith('0')) {
      inputVal = '';
    }

    inputVal = inputVal.toString().replace(/,/g, ''); // 쉼표(,) 제거

    setSearchParam({
      ...searchParam,
      [name]: inputVal,
    });
  };

  const postPointMinAmount = async () => {
    const newParams = {
      minUseAmount: searchParam.minUseAmount === '' ? 0 : searchParam.minUseAmount,
      siteID: location?.state.params.siteID,
    };
    const { result } = await CallPostPointMinAmount(Number(storeID), newParams);
    if (result.code === 1) {
      alert('최소 사용 금액 설정이 완료되었습니다.');
      checkGoBack();
    } else if (result.code === -1) {
      alert('포미DB에서 매장을 가지고 있지 않은 경우');
    } else {
      alert(result.message);
      console.log('실패: code =', result.code, result.message);
    }
  };

  const isSave = () => {
    if (searchParam.minPriceType !== AccumulatePriceType.none && !searchParam.minUseAmount) {
      return alert('최소 사용 금액을 확인해주세요.');
    }

    return postPointMinAmount();
  };

  const getPointMinAmount = async (storeID: number) => {
    const { result, data } = await CallGetPointMinAmount(storeID, paramStatus);
    if (result.code === 1) {
      setSearchParam({
        ...searchParam,
        minUseAmount: data.minUseAmount,
        minPriceType: data.minUseAmount === 0 ? AccumulatePriceType.none : AccumulatePriceType.price,
      });
    } else {
      console.log('실패: code =', result.code);
    }
  };

  const checkGoBack = () => {
    navigate('/point/amount', { state: { storeStatus, paramStatus } });
  };

  useBackDetection(checkGoBack);

  useEffect(() => {
    getPointMinAmount(Number(storeID));
  }, [storeID]);

  return (
    <div className="pointPageWrap amount">
      <p className="notiPlus">※ 위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.</p>
      <div className="searchArea">
        <select disabled>
          <option value={storeID}>{storeStatus}</option>
        </select>
      </div>
      <div className="detailFormArea">
        <div className="formItem">
          <span className="title">최소 사용 금액</span>
          <div className="radioType">
            <CommonRadioButton
              name="minPriceType"
              id="amount1"
              label="제한 없음"
              value={AccumulatePriceType.none}
              check={searchParam.minPriceType === AccumulatePriceType.none}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.none, 'minUseAmount')}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="minPriceType"
              id="amount2"
              label="최소"
              value={AccumulatePriceType.price}
              check={searchParam.minPriceType === AccumulatePriceType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.price)}
            />
            <div className="inputLabel">
              <input
                type="text"
                inputMode="numeric"
                placeholder="1"
                name="minUseAmount"
                value={numberWithCommas(searchParam.minUseAmount)}
                disabled={searchParam.minPriceType !== AccumulatePriceType.price}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />
              <span>P 부터 사용</span>
            </div>
          </div>
        </div>
      </div>
      <div className="fixedBtnGroup">
        <button type="button" id="btnSubmit" className="btnDone" onClick={() => isSave()}>
          저장
        </button>
      </div>
    </div>
  );
};

export default PointAmountEdit;
