/* eslint-disable jsx-a11y/label-has-associated-control */
import NumberCommaInput from '@src/components/molecules/input/NumberCommaInput';
import useBackDetection from '@src/hooks/useBackDetection';
import { numberWithCommasToNum } from '@src/library/Utils';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const TableListAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectStore = location?.state || ''; // 수정 페이지에서 받아오는 select value
  const [count, setCount] = useState(0);
  const isPopup = location.search.includes('isPopup'); // 주접프 새창 오픈 유무

  const onChangeNumberInput = (e: React.ChangeEvent<HTMLInputElement>, id: string, value: string) => {
    setCount(numberWithCommasToNum(value));
  };

  const onClickSave = () => {
    const confirm = window.confirm(`테이블 ${count}개를 일괄 추가 하시겠습니까?`);
    if (confirm) checkGoBack();
  };

  const checkGoBack = () => {
    navigate(`/table/list${isPopup ? '?isPopup' : ''}`, {
      state: { selectStore, count },
    });
  };

  useBackDetection(checkGoBack);

  return (
    <div className="table-page-wrap">
      <div className="searchArea">
        <select disabled>
          <option>{selectStore}</option>
        </select>
      </div>

      <div className="detailFormArea">
        <div className="formItem">
          <div className="formUnit table-add">
            <span className="title">테이블</span>
            <label htmlFor="count">
              <NumberCommaInput
                placeholder="1"
                id="count"
                name="count"
                value={count}
                onChange={onChangeNumberInput}
                className="table-cnt"
                max={30}
              />
              개
            </label>
          </div>
          <div className="notice">
            <p>입력한 테이블 개수만큼 테이블별 QR코드가 생성됩니다.</p>
            <p>테이블은 최대 30개까지 추가 가능합니다.</p>
            <p>QR코드 생성 시 테이블 번호가 표기된 기본 템플릿으로 생성되며, 템플릿은 변경 불가합니다.</p>
            <p>테이블 추가 시 마지막으로 생성되어 있는 테이블의 다음 번호로 하여 순차적으로 생성됩니다</p>
            <p>
              테이블 주문 사용 설정은 [파트너스] - [테이블 주문 관리] - [테이블 주문 설정] 또는 [주문접수프로그램/APP] -
              [테이블 Tab] 최초 진입 시 테이블 주문 이용 가능하도록 설정할 수 있습니다.
            </p>
            <p>
              테이블 생성 후 즉시 QR코드 일괄 또는 개별 다운로드 가능합니다. 단, 저장 버튼을 누르지 않으면 생성된 테이블
              정보가 삭제되니, 테이블 생성 후 반드시 저장해 주세요.
            </p>
          </div>
        </div>
      </div>
      <div className="fixedBtnGroup">
        <button type="button" id="btnSubmit" className="btnDone" disabled={!count} onClick={onClickSave}>
          확인
        </button>
      </div>
    </div>
  );
};
