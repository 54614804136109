import { CallPostPomiCancel } from '@src/library/request/callPoint';
import { PointUseStatusEn } from '@src/types/pointType';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PointListDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detailData = location?.state;
  const {
    orderNo,
    storeName,
    executeAtTitle,
    executeAt,
    amount,
    created,
    mobile,
    executeType,
    executeTypeText,
    isCancelDisplay,
    cancelText,
  } = detailData;

  const paramData = {
    orderNo,
  };

  const isDelete = (type: string) => {
    if (window.confirm(`포인트 ${type === 'use' ? '사용' : '적립'}을 취소하시겠습니까?`)) {
      callDeletePomiCancel(type, paramData);
    }
  };

  const callDeletePomiCancel = async (type: string, param: { orderNo: string }) => {
    const { result } = await CallPostPomiCancel(type, param);
    if (result.code === 1) {
      navigate('/point/list');
    } else {
      console.log('실패: code =', result.code);
    }
  };

  const pointColorType = (executeType: number) => {
    if (executeType === 10 || executeType === 21 || executeType === 50 || executeType === 61) {
      return 'typeBlue';
    }
    return 'typeRed';
  };

  const isNotiPoint = () => {
    if (!isCancelDisplay) {
      return <p className="notiPoint">{cancelText}</p>;
    }
    return null;
  };

  return (
    <div className="pointPageWrap">
      <div className="detailArea">
        <div className="title">
          <p>포인트 정보</p>
          <span className={`stateLabel ${PointUseStatusEn[Number(executeType)]}`}>{executeTypeText}</span>
        </div>
        <dl>
          {executeTypeText !== '소멸' && (
            <>
              <dt>주문번호</dt>
              <dd>{orderNo}</dd>
            </>
          )}

          <dt>매장명</dt>
          <dd>{storeName}</dd>
          <dt>{executeAtTitle}</dt>
          <dd>{executeAt}</dd>
          <dt>포인트</dt>
          <dd className={`${pointColorType(Number(executeType))}`}>{Number(amount).toLocaleString()} P</dd>
          <dt>일시</dt>
          <dd>{created}</dd>
          <dt>회원 휴대폰 번호</dt>
          <dd>{mobile}</dd>
        </dl>
        <div className="notiPointBox">{isNotiPoint()}</div>
      </div>

      {(executeTypeText === '사용' || executeTypeText === '적립') && (
        <div className="fixedBtnGroup">
          <button
            type="button"
            id="btnSubmit"
            className="btnDone"
            disabled={!isCancelDisplay}
            onClick={() => isDelete(`${executeTypeText === '사용' ? 'use' : 'save'}`)}
          >
            {executeTypeText === '사용' ? '사용 취소' : '적립 취소'}
          </button>
        </div>
      )}
    </div>
  );
};

export default PointListDetail;
