import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PosProgram from './components/PosProgram';
import StoreApp from './components/StoreApp';

const DownloadPomi = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') || 'program'; // program, app

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setSearchParams({ type: value });
  };

  return (
    <div className="downloadWrap">
      <aside id="mAside">
        <div className="download-select">
          <select id="downMethod" name="downMethod" defaultValue={type} onChange={handleSelect}>
            <option value="program">사장님 프로그램</option>
            <option value="app">매장 APP</option>
          </select>
        </div>
      </aside>
      {type === 'program' ? <PosProgram /> : <StoreApp />}
    </div>
  );
};

export default DownloadPomi;
