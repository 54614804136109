import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// 뒤로가기 감지하는 custom hook
const useBackDetection = (callback: () => void) => {
  const location = useLocation();
  const handleGoBack = (event: PopStateEvent) => {
    event.preventDefault();
    callback();
  };

  useEffect(() => {
    window.history.pushState(null, '', location.pathname);

    window.addEventListener('popstate', handleGoBack);

    return () => {
      window.removeEventListener('popstate', handleGoBack);
    };
  }, []);
};

export default useBackDetection;
