import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { HOST_API, HOST_API_AUTH, isDevEnv } from '@src/library/Constants';
import { ApiParamType, ResponseType } from '@src/types/apiType';

export const setFormData = (obj: Record<string, any>, prefix?: string): string => {
  const str: string[] = [];
  Object.keys(obj).forEach((key) => {
    const k = prefix ? `${prefix}[${key}]` : key;
    const v = obj[key];

    if (v !== null && typeof v === 'object') {
      str.push(setFormData(v as Record<string, unknown>, k));
    } else {
      str.push(`${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`);
    }
  });
  return str.join('&');
};

const token = Cookies.get('token');

export const request = async <T = undefined>(param: ApiParamType): Promise<ResponseType<T>> => {
  const { url, method, data, host } = param;

  let response;
  const config: AxiosRequestConfig = {
    baseURL: HOST_API,
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      token,
    },
  };

  if (host === 'auth') {
    config.baseURL = HOST_API_AUTH;
  }

  if (data && (method === 'post' || method === 'put')) {
    config.data = setFormData(data);
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  if (method === 'get') {
    config.params = data;
  }

  if (method === 'postFile') {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  if (method === 'getExcel') {
    config.method = 'get';
    config.params = data;
    config.headers = { Accept: 'text/csv', 'Content-Type': 'application/json', token };
  }

  if (data && method === 'postJson') {
    config.method = 'post';
    config.data = data;
  }

  try {
    response = await axios(config);
  } catch (error) {
    if (isDevEnv) {
      if (error.response) {
        console.log('request 요청 오류 발생 - 상태 코드 2xx 외의 값', error.response.status);
      } else if (error.request) {
        console.log('request 요청 오류 발생 - 응답이 전송되지 않음', error.request);
      } else {
        console.log('request 요청 오류 발생 - 요청 설정 중 문제 발생', error.message);
      }
      console.log('요청 한 config: ', error.config);
    }
    // 매니저 접근 불가
    if (error.response.status === 401) {
      alert(error.response.data.result.message);
      window.history.back();
    }
    return { result: { code: 0, message: '' } };
  }
  return response.data;
};

export default request;
