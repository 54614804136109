import React, { ChangeEvent, useState, useEffect } from 'react';
import moment from 'moment';

type TimeInputType = {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | undefined) => void;
  type?: 'hour' | 'min'; // hour (시), min(분)
  isDefaultVal?: boolean;
  placeholder?: string;
  value: string;
  name?: string;
  disabled?: boolean;
  hour24?: boolean; // 기본적으로 23시 까지 노출되지만 24시 노출이 필요한 경우
};

const getHourOptionList = (isInclude24: boolean) => {
  const hourLimit: number = isInclude24 ? 25 : 24;
  const hour = [];
  for (let i = 0; i < hourLimit; i += 1) {
    hour.push(i < 10 ? `0${i}` : i.toString());
  }
  return hour;
};

const getMinutes = () => {
  const minutes = [];
  for (let i = 0; i <= 50; i += 10) {
    minutes.push(i < 10 ? `0${i}` : `${i}`);
  }
  return minutes;
};

const TimeInput = (props: TimeInputType) => {
  const {
    onChange,
    type = 'hour',
    isDefaultVal = true,
    placeholder = '',
    value = '',
    name = '',
    disabled = false,
    hour24 = false,
  } = props;

  const [initialValue, setInitialValue] = useState(value);
  const options = type === 'hour' ? getHourOptionList(hour24) : getMinutes();

  useEffect(() => {
    if (isDefaultVal) {
      if (type === 'min') {
        const currentMinutes = parseInt(moment().format('mm'), 10);
        const initialMinutes = Math.floor(currentMinutes / 10) * 10 + 10;
        const initialMinutesStr = initialMinutes.toString();
        const paddedMinutesStr = initialMinutesStr.length < 2 ? `0${initialMinutesStr}` : initialMinutesStr;
        setInitialValue(paddedMinutesStr);
      } else {
        const initialHour = moment().format('HH');
        setInitialValue(initialHour);
      }
    }
  }, [type]);

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  return (
    <select
      className="searchItem"
      onChange={onChange}
      value={isDefaultVal ? initialValue : ''}
      name={name}
      disabled={disabled}
    >
      {!isDefaultVal && (
        <option value="" disabled hidden>
          {placeholder || ''}
        </option>
      )}
      {options.map((op, index) => {
        return (
          <option key={`${op}_${index}`} value={op}>
            {op}
          </option>
        );
      })}
    </select>
  );
};

export default TimeInput;
