import React, { useEffect, useCallback, useRef } from 'react';
import TinyPicker from './tinyPicker';

interface DateRangePickerType {
  startDate?: Date;
  minStartDate?: Date;
  maxStartDate?: Date;
  endDate?: Date; // 끝 날짜를 표시하고 싶으시면 endDate 를 보내주세요
  allowPast?: boolean;
  isPeriod?: boolean; // start ~ end 뿌려줄건지, 하나만 뿌릴건지? (true: 시작~끝, false: 시작)
  isRightOpen?: boolean; // 달력캘린더를 오른쪽에서 오픈할것인지 (왼쪽짤리는 현상 방지)
  periodAllowOneSelect?: boolean; // 시작 날짜만 선택해도 입력 가능하게 한다.
  onChange?: (startDate: Date, endDate: Date) => void;
  disabled?: boolean;
  isIcon?: boolean; // 달력 아이콘 유뮤 (default는 false)
  startPlaceholder?: string;
  endPlaceholder?: string;
  isThreeMonthsAgo?: boolean;
  onClick?: () => void;
}
const DateRangePicker: React.FC<DateRangePickerType> = (props) => {
  const {
    startDate,
    endDate,
    minStartDate,
    maxStartDate,
    allowPast = true,
    isPeriod = false,
    isRightOpen = false,
    onChange,
    periodAllowOneSelect = false,
    disabled = false,
    isIcon = true,
    startPlaceholder = '',
    endPlaceholder = '',
    isThreeMonthsAgo = false,
    onClick,
  } = props;

  const firstBox = useRef<HTMLInputElement>(null);
  const lastBox = useRef<HTMLInputElement>(null);

  const getYesterday = useCallback(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
  }, []);

  useEffect(() => {
    const options = {
      firstBox: firstBox.current,
      ...(startDate && { startDate }),
      ...(isPeriod && { lastBox: lastBox.current }),
      ...(isPeriod && { endDate }),
      ...(minStartDate ? { minStartDate } : !isPeriod && { minStartDate: getYesterday() }),
      ...(maxStartDate && { maxStartDate }),
      ...(periodAllowOneSelect && { periodAllowOneSelect }),
      local: 'en-ko',
      pastMonths: 3,
      allowPast,
      months: 1,
      success: onChange,
      isRightOpen,
      disabled,
      isIcon,
      isThreeMonthsAgo,

      err() {
        alert('날짜를 다시 선택해주세요.');
      },
    };

    // firstBox 가 렌더링될 때 state 값이 아직 업데이트 안된 경우.
    if (!firstBox.current) {
      return () => {};
    }
    const DatePicker = new TinyPicker(options);
    DatePicker.init();

    return () => {
      DatePicker.cleanup();
    };
  });

  useEffect(() => {
    if (startDate && minStartDate && startDate < minStartDate) {
      if (onChange) {
        onChange(minStartDate, minStartDate);
      } else {
        console.log('onChange function not defined.');
      }
    }
  }, [startDate, minStartDate]);

  useEffect(() => {
    if (startDate && endDate && maxStartDate && endDate > maxStartDate) {
      onChange(startDate, maxStartDate);
    }
  }, [startDate, endDate, maxStartDate]);
  return (
    <>
      <input
        className={`${isIcon && 'icon'}`}
        type="text"
        id="startDate"
        ref={firstBox}
        autoComplete="off"
        readOnly
        placeholder={startPlaceholder || '시작일'}
        disabled={disabled}
        onClick={onClick}
      />
      {isPeriod && (
        <>
          <span className="range">~</span>
          <input
            className={`${isIcon && 'icon'}`}
            type="text"
            id="endDate"
            ref={lastBox}
            autoComplete="off"
            readOnly
            placeholder={endPlaceholder || '종료일'}
            disabled={disabled}
            onClick={onClick}
          />
        </>
      )}
    </>
  );
};
export default DateRangePicker;
