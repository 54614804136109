import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import PointOnlineAddBasic from '@src/pages/point/online/addBasic';
import PointOnlineAddStore from '@src/pages/point/online/addStore';
import { AccumulatePriceType, AccumulateType, InitPointData, InitPointDataType, ModeType } from '@type/pointType';
import { isValidDate, isValidTime } from '@type/date/dateHelper';
import { CallGetPointList, CallPostPointOnlineEvent, CallPutPointOnlineEvent } from '@library/request/callPoint';

// 포인트 등록/수정 => beginDate/endDate (적용기간)
// 포인트 리스트 조회 => searchStartDate/searchEndDate (등록기간)
// 포인트 동기간 체크  =>  beginDate/endDate (적용기간)

type OnlineAddProsType = {
  mode?: (typeof ModeType)[keyof typeof ModeType];
};

const PointOnlineAdd = (props: OnlineAddProsType) => {
  const { mode = '' } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { eventID } = useParams();
  const detailData = location?.state;
  const [pointData, setPointData] = useState<InitPointDataType>(InitPointData);

  const formControl = useForm<FieldValues>({
    values: pointData,
  });
  const { watch } = formControl;
  const {
    name,
    accumulateType,
    reserveRate,
    reserveAmount,
    maxPriceType,
    maxReserveAmount,
    minPriceType,
    minOrderAmount,
    beginDate,
    endDate,
    day,
    beginTime,
    endTime,
    validTermType,
    pointValidTerm,
    storeIDs,
    week,
  } = watch();

  // new
  const postOnlineEvent = async (param: InitPointDataType) => {
    try {
      const { result } = await CallPostPointOnlineEvent(param);
      if (result.code === 1) {
        alert('포인트 설정이 완료 되었습니다.\n포인트 적용 버튼 클릭 시 발급 처리 됩니다.');
        navigate(`/point/online`);
      } else {
        alert(result.message);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  // edit
  const putOnlineEvent = async (param: InitPointDataType) => {
    const newParam = { event: param };
    try {
      const { result } = await CallPutPointOnlineEvent(Number(eventID), newParam);
      if (result.code === 1) {
        alert('포인트 설정이 완료 되었습니다.\n포인트 적용 버튼 클릭 시 발급 처리 됩니다.');
        navigate('/point/online');
      } else {
        alert(result.message);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const focusElem = (id: string | null, name?: string) => {
    const obj = id ? document.getElementById(id) : name ? document.getElementsByName(name)[0] : null;
    obj.scrollIntoView({ behavior: 'auto', block: 'center' });
  };

  // 등록/수정 모두 동기간 체크
  const checkSamePeriod = async () => {
    if (mode !== ModeType.detail) {
      const searchParam = { beginDate: `${beginDate}:00`, endDate: `${endDate}:00`, status: 1 };
      const { result, data = [] } = await CallGetPointList(searchParam);
      const isEventExisting = result.code === 1 && data.length > 0;

      if (
        isEventExisting &&
        !window.confirm('동일 적립 기간 내 진행 중인 포인트 이벤트가 있습니다. 저장하시겠습니까?')
      ) {
        focusElem('dateType', null);
        return false;
      }
    }
    window.scrollTo(0, 0);
    navigate('store');
    return true;
  };

  const onClickNextBtn = async () => {
    await onCheckValidate();
  };

  const onCheckValidate = async (): Promise<boolean> => {
    const showAlert = (message: string, elementName?: string | null, elementId?: string | null) => {
      alert(message);
      focusElem(elementId, elementName);
      return false;
    };
    if (!name) {
      return showAlert('포인트명을 확인해주세요.', 'name');
    }
    if (accumulateType === AccumulateType.percent && !reserveRate) {
      return showAlert('적립 방법을 확인해주세요.', 'reserveRate');
    }
    if (accumulateType === AccumulateType.price && !reserveAmount) {
      return showAlert('적립 방법을 확인해주세요.', 'reserveAmount');
    }
    if (accumulateType === AccumulateType.price && reserveAmount >= 1000000) {
      return showAlert('1,000,000 이하 숫자만 입력 가능합니다.', 'reserveAmount');
    }
    if (maxPriceType !== AccumulatePriceType.none && !maxReserveAmount) {
      return showAlert('최대 적립 금액을 확인해주세요.', 'maxReserveAmount');
    }
    if (maxPriceType !== AccumulatePriceType.none && maxReserveAmount >= 1000000) {
      return showAlert('1,000,000 이하 숫자만 입력 가능합니다.', 'maxReserveAmount');
    }
    if (minPriceType !== AccumulatePriceType.none && !minOrderAmount) {
      return showAlert('최소 결제 금액을 확인해주세요.', 'minOrderAmount');
    }
    if (minPriceType !== AccumulatePriceType.none && minOrderAmount >= 1000000) {
      return showAlert('1,000,000 이하 숫자만 입력 가능합니다.', 'minOrderAmount');
    }
    if (!isValidDate(beginDate, endDate)) {
      return showAlert('적립 기간의 시작과 종료일이 동일하게 설정할 수 없습니다.', null, 'startDate');
    }
    if (day === '0000000') {
      return showAlert('적용 주기를 확인해주세요.', null, 'dateWeek');
    }
    if (!beginTime || !endTime || !isValidTime(beginTime, endTime)) {
      return showAlert('적용 주기의 시작과 종료 시간을 동일하게 설정할 수 없습니다.', null, 'dateWeek');
    }
    if (validTermType !== AccumulatePriceType.none && !pointValidTerm) {
      return showAlert('유효기간을 확인해주세요.', 'pointValidTerm');
    }
    if (validTermType !== AccumulatePriceType.none && pointValidTerm >= 1000000) {
      return showAlert('1,000,000 이하 숫자만 입력 가능합니다.', 'pointValidTerm');
    }
    await checkSamePeriod();

    return true;
  };

  const onClickSave = () => {
    const dataParam = {
      name,
      beginDate,
      endDate,
      reserveRate: accumulateType === AccumulateType.percent ? reserveRate : 0,
      reserveAmount: accumulateType === AccumulateType.price ? reserveAmount : 0,
      minOrderAmount: minPriceType === AccumulatePriceType.none ? 0 : minOrderAmount,
      maxReserveAmount: maxPriceType === AccumulatePriceType.none ? 0 : maxReserveAmount,
      pointValidTerm: validTermType === AccumulatePriceType.none ? 0 : pointValidTerm,
      week,
      day,
      beginTime,
      endTime,
      storeIDs,
    };
    if (mode === ModeType.edit) {
      putOnlineEvent(dataParam);
    } else {
      postOnlineEvent(dataParam);
    }
  };

  useEffect(() => {
    if (mode === ModeType.edit && detailData) {
      const reData = {
        ...detailData,
        accumulateType: detailData.reserveRate ? AccumulateType.percent : AccumulateType.price,
        maxPriceType: !detailData.maxReserveAmount ? AccumulatePriceType.none : AccumulatePriceType.price,
        minPriceType: !detailData.minOrderAmount ? AccumulatePriceType.none : AccumulatePriceType.price,
        validTermType: !detailData.pointValidTerm ? AccumulatePriceType.none : AccumulatePriceType.price,
      };
      setPointData(reData as InitPointDataType);
    }
  }, [mode]);

  return (
    <Routes>
      <Route
        path="/"
        element={<PointOnlineAddBasic formControl={formControl} onClickNextBtn={onClickNextBtn} mode={mode} />}
      />
      <Route
        path="/store"
        element={<PointOnlineAddStore formControl={formControl} onClickSave={onClickSave} mode={mode} />}
      />
    </Routes>
  );
};

export default PointOnlineAdd;
