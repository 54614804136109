import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { CallGetPointDetail, CallGetPointList, CallPostOnlineEventStatus } from '@src/library/request/callPoint';
import {
  PointListDetailEvent,
  PointListDetailList,
  PointStatusKo,
  PointStatusEn,
  PointStatus,
} from '@src/types/pointType';
import { getEndDate } from '@src/pages/point/online/online';
import { numberWithCommas } from '@library/Utils';
import useBackDetection from '@src/hooks/useBackDetection';

const PointOnlineDetail = () => {
  const { eventID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevSearchParam = location?.state?.searchParam || null;
  const prevDateType = location?.state?.dateType || 0;

  const [detailData, setDetailData] = useState<PointListDetailEvent>({
    reserveText: '',
    reserveDateTime: '',
    ID: '',
    name: '',
    maxReserveAmount: 0,
    minOrderAmount: 0,
    beginDate: '',
    endDate: '',
    pointValidTerm: 0,
    created: '',
    status: '',
  });
  const [detailList, setDetailList] = useState<PointListDetailList[]>([]);
  const isInit = useRef(true);

  const callGetPointDetail = async () => {
    const { result, data } = await CallGetPointDetail(Number(eventID));
    if (result.code === 1) {
      setDetailData(data.event);
      setDetailList(data.stores);
    }
  };

  const checkSamePeriod = async () => {
    const searchParam = { beginDate: detailData?.beginDate, endDate: detailData?.endDate, status: 1 };
    const { result, data = [] } = await CallGetPointList(searchParam);
    const isEventExisting = result.code === 1 && data.length > 0;
    return !(
      isEventExisting && !window.confirm('동일 적립 기간 내 진행 중인 포인트 이벤트가 있습니다. 적용하시겠습니까?')
    );
  };

  const callPostOnlineEventStatus = async (type: number) => {
    try {
      const status = type === PointStatus.save ? 1 : 4;
      if (type === PointStatus.save) {
        const isCheck = await checkSamePeriod();
        if (isCheck) {
          const { result } = await CallPostOnlineEventStatus(Number(eventID), status);
          alert('포인트 적용이 완료되었습니다.');
          if (result.code === 1) {
            // 상태값 변경 성공시 라벨 변경하기위해 데이터 다시 호출
            callGetPointDetail();
          }
        }
      } else if (window.confirm('포인트 발급이 중지됩니다. 진행 하시겠습니까?')) {
        const { result } = await CallPostOnlineEventStatus(Number(eventID), status);
        if (result.code === 1) {
          // 상태값 변경 성공시 라벨 변경하기위해 데이터 다시 호출
          callGetPointDetail();
        }
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const checkGoBack = () => {
    // 뒤로갈 경우 리스트에서 받은 데이터 같이 넘김
    navigate('/point/online', { state: { prevSearchParam, prevDateType } });
  };

  useBackDetection(checkGoBack);

  useEffect(() => {
    callGetPointDetail();
    window.scrollTo(0, 0);
    isInit.current = false;
  }, []);

  return (
    <div className="pointPageWrap">
      {isInit.current ? (
        <span />
      ) : (
        <>
          <div className="detailArea">
            <div className="title">
              <p>포인트 정보</p>
              <span className={`stateLabel ${PointStatusEn[Number(detailData.status)]}`}>
                {PointStatusKo[Number(detailData.status)]}
              </span>
            </div>
            <dl>
              <dt>포인트명</dt>
              <dd className="typeBold">{detailData.name}</dd>
              <dt>적립 방법</dt>
              <dd className="typeBlue">{detailData.reserveText}</dd>
              <dt>최대 적립 금액</dt>
              <dd>
                {detailData.maxReserveAmount === 0
                  ? '제한 없음'
                  : `최대 ${numberWithCommas(detailData.maxReserveAmount)}P 적립`}
              </dd>
              <dt>최소 결제 금액</dt>
              <dd>
                {detailData.minOrderAmount === 0
                  ? '제한 없음'
                  : `최소 ${numberWithCommas(detailData.minOrderAmount)}원 이상 결제 시 적립`}
              </dd>
              <dt>적립기간</dt>
              <dd>
                {`${moment(detailData.beginDate).format('YYYY.MM.DD HH:mm')}~${getEndDate(
                  moment(detailData.endDate).format('YYYY.MM.DD HH:mm'),
                )}`}
              </dd>
              <dt>적용 주기</dt>
              <dd dangerouslySetInnerHTML={{ __html: detailData.reserveDateTime }} />
              <dt>유효기간</dt>
              <dd>
                {detailData.pointValidTerm === 0
                  ? '제한 없음'
                  : `적립일로 부터 ${numberWithCommas(detailData.pointValidTerm)}일`}
              </dd>
              <dt>적용 매장</dt>
              <dd>
                {detailList &&
                  detailList.map((v, i) => {
                    const { ID, name } = v;
                    return (
                      <p key={`${ID}-${i}`}>
                        [{ID}] {name}
                      </p>
                    );
                  })}
              </dd>
              <dt>등록일</dt>
              <dd>{moment(detailData.created).format('YYYY.MM.DD')}</dd>
            </dl>
          </div>
          {Number(detailData.status) !== PointStatus.stop && Number(detailData.status) !== PointStatus.finish && (
            <div className="fixedBtnGroup">
              <button
                type="button"
                id="btnSubmit"
                className="btnEdit"
                onClick={() => navigate(`/point/online/edit/${eventID}`, { state: detailData })}
                disabled={Number(detailData.status) === PointStatus.publish}
              >
                수정
              </button>
              <button
                type="button"
                id="btnSubmit"
                className="btnDone"
                onClick={() => callPostOnlineEventStatus(Number(detailData.status))}
              >
                {Number(detailData.status) === PointStatus.save ? '포인트 적용' : '발급 중지'}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PointOnlineDetail;
