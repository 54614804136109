import React, { useEffect } from 'react';
import TimeInput from '@components/molecules/input/TimeInput';

type DateTimeInputType = {
  onChangeDateTime: (name: string, value: string) => void;
  beginName: string;
  endName: string;
  beginTime: string;
  endTime: string;
  hour24?: boolean; // 기본적으로 23시 까지 노출되지만 24시 노출이 필요한 경우
};

const TimeInputRange: React.FC<DateTimeInputType> = (props) => {
  const { onChangeDateTime, beginTime, endTime, beginName, endName, hour24 = false } = props;

  const getSplitHourMin = (time: string) => {
    const splitTime = time.split(':');
    return { hour: splitTime[0], min: splitTime[1] };
  };

  const beginDateHourMin: { hour: string; min: string } = getSplitHourMin(beginTime || '00:00:00');
  const endDateHourMin: { hour: string; min: string } = getSplitHourMin(endTime || '00:00:00');

  const onBeginChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(beginName, `${value}:${beginDateHourMin.min}:00`);
  };

  const onBeginChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(beginName, `${beginDateHourMin.hour}:${value}:00`);
  };

  const onEndChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(endName, `${value}:${endDateHourMin.min}:00`);
  };

  const onEndChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeDateTime(endName, `${endDateHourMin.hour}:${value}:00`);
  };

  useEffect(() => {
    if (beginTime && endTime && beginTime > endTime) {
      onChangeDateTime(endName, beginTime);
    }
  }, [beginTime, endTime]);

  return (
    <div className="commonTimeInputRange">
      <div className="dateWrap">
        <TimeInput name={beginName} value={beginDateHourMin.hour} onChange={onBeginChangeHour} hour24={hour24} />
        :
        <TimeInput name={beginName} value={beginDateHourMin.min} onChange={onBeginChangeMin} type="min" />
        <span>부터</span>
      </div>
      <div className="dateWrap">
        <TimeInput name={endName} value={endDateHourMin.hour} onChange={onEndChangeHour} hour24={hour24} />
        :
        <TimeInput name={endName} value={endDateHourMin.min} onChange={onEndChangeMin} type="min" />
        <span> 까지</span>
      </div>
    </div>
  );
};

export default React.memo(TimeInputRange);
