import { toastState } from '@src/recoil/toast';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

interface ToastProps {
  floatingSecond?: number;
}

/**
 * @param props
 * @constructor
 */
const Toast = (props: ToastProps) => {
  const { floatingSecond = 2000 } = props;
  const [toast, setToast] = useRecoilState(toastState);
  const { isOpen, message } = toast;

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isOpen) {
      timer = setTimeout(() => {
        setToast({ isOpen: false, message: '' });
      }, floatingSecond);
    }

    return () => {
      if (isOpen) clearTimeout(timer);
    };
  }, [toast, floatingSecond]);

  return <div className={`toast ${toast.isOpen ? 'open' : ''}`}>{message}</div>;
};

export default Toast;
