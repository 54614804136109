import { request } from '@src/library/API';
import {
  GetPointHistoryType,
  OfflinePutType,
  PointHistoryListType,
  PointListDetailType,
  PointOfflineType,
  PomiStoreListType,
  PointOnlineType,
  InitPointDataType,
} from '@src/types/pointType';

import { ApiMethodType } from '@src/types/apiType';

export type GetPointListType = {
  page?: number;
  limit?: number;
  status?: number;
  name?: string;
  beginDate?: string;
  endDate?: string;
};

export type minAmountType = {
  minUseAmount: number | string;
};

export type pointMinAmountType = {
  storeName: string;
  siteID: number;
};

export const CallGetPointList = (param?: GetPointListType) =>
  request<PointOnlineType[]>({ url: '/biz/online-events', method: 'get', data: param });

export const CallGetPointDetail = (eventID: number) =>
  request<PointListDetailType>({ url: `/biz/online-events/${eventID}`, method: 'get' });

export const CallGetPointOfflineEvent = (storeID: number) =>
  request<PointOfflineType>({ url: `/biz/stores/${storeID}/current-offline-event`, method: 'get' });

export const CallPutPointOfflineEvent = (storeID: number, param: OfflinePutType) =>
  request({ url: `/biz/stores/${storeID}/offline-event`, method: 'put', data: param });

export const CallGetPointHistory = (param: GetPointHistoryType, type: ApiMethodType) =>
  request<PointHistoryListType>({ url: `/biz/pomi/history`, method: type, data: param });

export const CallGetPointMinAmount = (storeID: number, param: pointMinAmountType) =>
  request<minAmountType>({ url: `/biz/stores/${storeID}/pomi/min-amount`, method: 'get', data: param });

export const CallPostPointMinAmount = (storeID: number, param: minAmountType) =>
  request<minAmountType>({ url: `/biz/stores/${storeID}/pomi/min-amount`, method: 'post', data: param });

export const CallGetPomiStores = () => request<PomiStoreListType[]>({ url: `/biz/pomi/stores`, method: 'get' });

export const CallPostPointOnlineEvent = (param: InitPointDataType) =>
  request<InitPointDataType>({ url: '/biz/online-events', method: 'post', data: param });

export const CallPostOnlineEventStatus = (eventID: number, status: number) =>
  request({ url: `/biz/online-events/${eventID}/status`, method: 'post', data: { status } });

export const CallPutPointOnlineEvent = (eventID: number, param: { event: InitPointDataType }) =>
  request<InitPointDataType>({ url: `/biz/online-events/${eventID}`, method: 'put', data: param });

export const CallPostPomiCancel = (type: string, param: { orderNo: string }) =>
  request({ url: `/biz/pomi/${type}/cancel`, method: 'post', data: param });
