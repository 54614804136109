import React, { useEffect } from 'react';
import '@src/styles/_term.scss';

const PrivacyTerms = () => {
  useEffect(() => {
    document.title = '포미포인트 개인정보 수집 및 이용약관';
  }, []);

  return (
    <div className="term-wrapper">
      <h1 className="title">포미포인트 개인정보 수집 및 이용약관 </h1>

      <p>
        “회사”는 서비스 제공을 위한 최소한의 범위 내에서 “회원”의 동의 하에 개인정보를 수집하며, 수집한 모든 개인정보는
        고지한 목적 범위 내에서만 이용됩니다. <br />
        회원 정보의 수집 항목, 이용 목적, 보유 및 이용 기간은 아래와 같습니다.
      </p>

      <h2 className="subtitle">[수집 항목] </h2>
      <p>휴대폰번호</p>

      <h2 className="subtitle">[이용 목적] </h2>
      <p>회원 식별, 회원 서비스 제공, 중요 고지사항 전달, 서비스 이용 및 상담 등 원활한 의사소통 경로 확보 </p>

      <h2 className="subtitle">[보유 및 이용 기간] </h2>
      <p>
        <strong>회원탈퇴 신청 후 5일까지</strong> 또는 법령에 따른 보존기간 (단, 휴대폰번호는 부정이용방지를 위해
        <strong> 탈퇴 완료 후 6개월까지</strong>)
        <br />
        개인정보 수집 및 이용 동의를 거부하실 수 있으며, 거부 시 회원가입에 제한이 있습니다.
      </p>
    </div>
  );
};

export default PrivacyTerms;
