import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { bizStorage } from '@src/library/bizStorage';
import { AWS_IMAGE_URL, HOST_BIZ } from '@library/Constants';
import { LayoutHeaderProps } from '@type/layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { goLoginPage, throttle } from '@library/Utils';
import { StorageCompanyType } from '@src/types/storageType';

function LayoutHeader(props: LayoutHeaderProps) {
  const { title, onMenu, backUrl, message } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = Cookies.get('isLogin');
  const company = bizStorage.getItem<StorageCompanyType>('company');

  const [isFixed, setIsFixed] = useState(false);
  const isPopup = useRef(location.search.includes('isPopup')); // 주접프 새창 오픈 유무

  const scrollControll = useCallback(() => {
    const { scrollY } = window;
    if (scrollY > 0) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, []);

  const throttledFn = useRef(throttle(scrollControll, 500));

  const goBack = (message?: string) => {
    if (message && !window.confirm(message)) {
      return;
    }
    window.history.back();
  };

  useEffect(() => {
    window.addEventListener('scroll', throttledFn.current);

    return () => {
      window.removeEventListener('scroll', throttledFn.current);
    };
  }, []);

  return (
    <header className="layoutHeader">
      <div className={`mobile ${isFixed ? 'fixed' : ''}`}>
        <h2 className={`${backUrl ? 'backTitle' : ''}`}>{title}</h2>
        {backUrl && (
          <button type="button" onClick={() => goBack(message)}>
            <img src={`${AWS_IMAGE_URL}/icon/m-icon-20-back.svg`} className="menu" alt="back" />
          </button>
        )}
        {!backUrl && !isPopup.current && (
          <button
            type="button"
            onClick={() => {
              onMenu(true);
            }}
          >
            <img src={`${AWS_IMAGE_URL}/icon/m-icon-menu-menu.svg`} className="menu" alt="메뉴 열기" />
          </button>
        )}
      </div>
      <div className="desktop">
        <h1>
          <Link to={`${HOST_BIZ}`}>
            <img src={`${AWS_IMAGE_URL}/logo/img-wmpo-partner-red-new.svg`} className="logo" alt="위메프오 파트너스" />
          </Link>
        </h1>
        {isLogin === '1' ? (
          <div className="authBox">
            <span>반갑습니다. </span>
            <button
              type="button"
              onClick={() => {
                window.location.href = `${HOST_BIZ}/membership/main`;
              }}
            >
              {company && company.owner} 님
            </button>
            <button
              className="logout"
              type="button"
              onClick={() => {
                navigate('/login/out');
              }}
            >
              로그아웃
            </button>
          </div>
        ) : (
          <div className="authBox">
            <span>위메프오 입점 사장님, </span>
            <button type="button" onClick={goLoginPage}>
              로그인
            </button>
            해주세요!
          </div>
        )}
      </div>
    </header>
  );
}

export default LayoutHeader;
