import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { HOST_BIZ } from '@src/library/Constants';
import Spinner from '@src/components/molecules/Spinner';
import { bizStorage } from '@src/library/bizStorage';
import { CallPostSignout } from '@src/library/request/callAuth';
import { getRootDomain } from '@src/library/Utils';

const Logout = () => {
  useEffect(() => {
    const signout = async () => {
      const { result } = await CallPostSignout();
      if (result.code === 1) {
        const rootDomain = getRootDomain();
        Cookies.remove('isLogin', { path: '/', domain: rootDomain });
        Cookies.remove('token', { path: '/', domain: rootDomain });
        Cookies.remove('companyID', { path: '/', domain: rootDomain });
        bizStorage.removeAll();
        window.location.replace(HOST_BIZ);
      }
    };
    signout();
  }, []);

  return <Spinner />;
};

export default Logout;
