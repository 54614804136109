import { AccumulatePriceType, AccumulateType, OfflinePutType } from '@src/types/pointType';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CommonRadioButton from '@src/components/molecules/radioButton/RadioButton';
import { CallPutPointOfflineEvent, CallGetPointOfflineEvent } from '@src/library/request/callPoint';
import useBackDetection from '@src/hooks/useBackDetection';
import { numberWithCommas } from '@src/library/Utils';

const PointOfflineEdit = () => {
  const navigate = useNavigate();
  const { storeID } = useParams();
  const location = useLocation();
  const storeStatus = location?.state;
  const [searchParam, setSearchParam] = useState<OfflinePutType>({
    name: '',
    reserveRate: '',
    reserveAmount: '',
    pointValidTerm: '',
    maxReserveAmount: '',
    accumulateType: AccumulateType.percent,
    maxPriceType: AccumulatePriceType.none,
    validTermType: AccumulatePriceType.none,
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name, max } = e.target;
    let inputVal = value.toString().replace(/[^\d]/g, ''); // 점(.)을 제외한 숫자만 추출

    // 첫 번째 입력 숫자가 0으로 시작하는 경우
    if (inputVal.startsWith('0')) {
      inputVal = '';
    }

    inputVal = inputVal.toString().replace(/,/g, ''); // 쉼표(,) 제거

    setSearchParam({
      ...searchParam,
      [name]: inputVal,
    });

    if (max) {
      const numVal = Number(inputVal);
      if (numVal > Number(max)) {
        setSearchParam({
          ...searchParam,
          [name]: Number(max),
        });
      }
    }
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>, val?: number | string, resetFiled?: string) => {
    const { name } = e.target as HTMLInputElement;

    setSearchParam({
      ...searchParam,
      [name]: val,
      [resetFiled]: '',
    });
  };

  const putPointOfflineEvent = async () => {
    const newParams = {
      name: '',
      reserveRate: searchParam.reserveRate === '' ? 0 : searchParam.reserveRate,
      reserveAmount: searchParam.reserveAmount === '' ? 0 : searchParam.reserveAmount,
      pointValidTerm: searchParam.pointValidTerm === '' ? 0 : searchParam.pointValidTerm,
      maxReserveAmount: searchParam.maxReserveAmount === '' ? 0 : searchParam.maxReserveAmount,
    };

    const { result } = await CallPutPointOfflineEvent(Number(storeID), newParams);
    if (result.code === 1) {
      alert('포인트 조건 설정이 완료되었습니다.');
      checkGoBack();
    } else {
      alert(result.message);
      console.log('실패: code =', result.code);
    }
  };

  const getPointOfflineEvent = async (storeID: number) => {
    const { result, data } = await CallGetPointOfflineEvent(storeID);
    if (result.code === 1) {
      setSearchParam({
        ...searchParam,
        reserveRate: data.reserveRate === 0 ? '' : data.reserveRate,
        reserveAmount: data.reserveAmount === 0 ? '' : data.reserveAmount,
        pointValidTerm: data.pointValidTerm === 0 ? '' : data.pointValidTerm,
        maxReserveAmount: data.maxReserveAmount === 0 ? '' : data.maxReserveAmount,
        accumulateType: data.reserveRate === 0 ? AccumulateType.price : AccumulateType.percent,
        maxPriceType: data.maxReserveAmount === 0 ? AccumulatePriceType.none : AccumulatePriceType.price,
        validTermType: data.pointValidTerm === 0 ? AccumulatePriceType.none : AccumulatePriceType.price,
      });
    } else {
      console.log('실패: code =', result.code);
    }
  };
  const isSave = () => {
    if (searchParam.accumulateType === AccumulateType.percent && !searchParam.reserveRate) {
      return alert('적립 방법을 확인해주세요.');
    }
    if (searchParam.accumulateType === AccumulateType.price && !searchParam.reserveAmount) {
      return alert('적립 방법을 확인해주세요.');
    }
    if (searchParam.validTermType !== AccumulatePriceType.none && !searchParam.pointValidTerm) {
      return alert('유효 기간을 확인해주세요.');
    }
    if (searchParam.maxPriceType !== AccumulatePriceType.none && !searchParam.maxReserveAmount) {
      return alert('최대 적립 금액을 확인해주세요.');
    }

    return putPointOfflineEvent();
  };

  const checkGoBack = () => {
    navigate('/point/offline', { state: storeStatus });
  };

  useBackDetection(checkGoBack);

  useEffect(() => {
    getPointOfflineEvent(Number(storeID));
  }, [storeID]);

  return (
    <div className="pointPageWrap offline">
      <p className="notiPlus">※ 위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.</p>
      <div className="searchArea">
        <select disabled>
          <option value={storeID}>{storeStatus}</option>
        </select>
      </div>
      {/* 적립 방법 */}
      <div className="detailFormArea">
        <div className="formItem">
          <span className="title">적립 방법</span>
          <div className="radioType">
            <CommonRadioButton
              name="accumulateType"
              id="accumulate1"
              label="결제 금액의"
              value={AccumulateType.percent}
              check={searchParam.accumulateType === AccumulateType.percent}
              onChangeHandler={(e) => {
                onChangeRadio(e, AccumulateType.percent, 'reserveAmount');
              }}
            />
            <div className="inputLabel">
              <input
                type="text"
                inputMode="numeric"
                name="reserveRate"
                placeholder="1"
                value={searchParam.reserveRate}
                disabled={searchParam.accumulateType !== AccumulateType.percent}
                max="100"
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />

              <span>% 적립</span>
            </div>
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="accumulateType"
              id="accumulate2"
              label="결제 금액과 상관없이"
              value={AccumulateType.price}
              check={searchParam.accumulateType === AccumulateType.price}
              onChangeHandler={(e) => {
                onChangeRadio(e, AccumulateType.price, 'reserveRate');
              }}
            />

            <div className="inputLabel">
              <input
                type="text"
                inputMode="numeric"
                placeholder="1"
                name="reserveAmount"
                value={numberWithCommas(searchParam.reserveAmount)}
                disabled={searchParam.accumulateType !== AccumulateType.price}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />
              <span>P 적립</span>
            </div>
          </div>
        </div>
        {/* 유효기간 */}
        <div className="formItem">
          <span className="title">유효기간</span>
          <div className="radioType">
            <CommonRadioButton
              name="validTermType"
              id="expiration1"
              label="제한 없음"
              value={AccumulatePriceType.none}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.none, 'pointValidTerm')}
              check={searchParam.validTermType === AccumulatePriceType.none}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="validTermType"
              id="expiration2"
              label="적립일로 부터"
              value={AccumulatePriceType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.price)}
              check={searchParam.validTermType === AccumulatePriceType.price}
            />
            <div className="inputLabel">
              <input
                type="text"
                inputMode="numeric"
                placeholder="1"
                name="pointValidTerm"
                value={numberWithCommas(searchParam.pointValidTerm)}
                disabled={searchParam.validTermType !== AccumulatePriceType.price}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />
              <span>일</span>
            </div>
          </div>
        </div>
        <div className="formItem">
          <span className="title">최대 적립 금액</span>
          <div className="radioType">
            <CommonRadioButton
              name="maxPriceType"
              id="maxAccumulate1"
              label="제한 없음"
              value={AccumulatePriceType.none}
              check={searchParam.maxPriceType === AccumulatePriceType.none}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.none, 'maxReserveAmount')}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="maxPriceType"
              id="maxAccumulate2"
              label="최대"
              value={AccumulatePriceType.price}
              check={searchParam.maxPriceType === AccumulatePriceType.price}
              onChangeHandler={(e) => onChangeRadio(e, AccumulatePriceType.price)}
            />
            <div className="inputLabel">
              <input
                type="text"
                inputMode="numeric"
                placeholder="1"
                name="maxReserveAmount"
                value={numberWithCommas(searchParam.maxReserveAmount)}
                disabled={searchParam.maxPriceType !== AccumulatePriceType.price}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              />
              <span>P 적립</span>
            </div>
          </div>
        </div>
      </div>
      <div className="fixedBtnGroup">
        <button type="button" id="btnSubmit" className="btnDone" onClick={() => isSave()}>
          저장
        </button>
      </div>
    </div>
  );
};

export default PointOfflineEdit;
