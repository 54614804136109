import React, { ChangeEvent } from 'react';
import { numberWithCommas } from '@library/Utils';
import { NumberCommaInputFormat, NumberCommaInputType } from './inputType';

// 3자리마다 콤마 찍는 컴포넌트
const NumberCommaInput = (data: NumberCommaInputType) => {
  const {
    id,
    name,
    className,
    style,
    placeholder,
    value = '',
    max,
    min,
    maxLength,
    decimalSize,
    format,
    disabled,
    readOnly,
    onBlur = () => {},
    onFocus = () => {},
    onChange = (e: React.ChangeEvent<HTMLInputElement>, id: string, value: string) => {},
    isPermitZero = false, // 0 입력 가능 유무(현재 쿠폰 분담금액 유일)
  } = data;

  const regex = {
    frontZeros: /^0+/,
    frontZeroNumber: /^0([0-9]+)/,
    frontDots: /^\.+/,
    allDots: /\./g,
    continuousDot: /\.+/g,
    notNumComma: /[^\d.]/g,
    decimalPart: /\.\d*/g,
    decimalPartOneMoreNumber: /\.\d+/g,
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;

    const setValue = (value: string) => {
      val = value;

      e.target.value = value;
    };

    if (val) {
      // 숫자, . 아닌 값 막기
      val = val.toString().replace(regex.notNumComma, '');

      // 맨앞 0 처리
      if (val.search(regex.frontZeroNumber) !== -1) {
        val = val.replace(regex.frontZeros, '');
      }

      if (max) {
        const numVal = Number(val);
        if (numVal > max) {
          setValue(`${value}`);
        }
      }

      if (min) {
        const numVal = Number(val);
        if (numVal < min) {
          setValue(`${value}`);
        }
      }

      if (maxLength) {
        val += '';
        if (val.length > maxLength) {
          setValue(`${value}`);
        }
      }

      if (!format) {
        val = val.toString().replace(regex.decimalPart, '');
      }

      if (format === NumberCommaInputFormat.float) {
        if (val.toString().search(regex.allDots) !== -1) {
          val = val.toString().replace(regex.frontDots, '');
          val = val.replace(regex.continuousDot, '.');

          // 소수점은 한개만 가능
          const decimal = val.match(regex.allDots);

          if (decimal && decimal.length > 1) {
            setValue(`${value}`);
          }

          // 소수점 길이 제한
          if (decimalSize) {
            const numVal = Number(val);
            const decimal = val.match(regex.decimalPartOneMoreNumber);
            if (decimal) {
              // 소수점(.) 을 뺀 길이
              if (decimal[0].length - 1 > decimalSize) {
                setValue(numVal.toFixed(decimalSize));
              }
            }
          }
        }
      }

      val = val.toString().replace(/,/g, ''); // 콤마 제거
    }

    onChange(e, id || name, val);
  };

  return (
    <input
      type="text"
      inputMode="numeric"
      id={id}
      name={name}
      className={`${className}`}
      style={style}
      placeholder={placeholder}
      value={!isPermitZero ? numberWithCommas(value) : value}
      disabled={disabled}
      readOnly={readOnly}
      onBlur={(e) => onBlur(e, id || name, `${value}`)}
      onFocus={(e) => onFocus(e, id || name, `${value}`)}
      onChange={(e) => handleChange(e)}
    />
  );
};

export default NumberCommaInput;
