import React from 'react';

const Spinner = (props: { show?: boolean }) => {
  const { show = true } = props;
  return (
    <div className={`spinner ${show ? 'on' : 'off'}`}>
      <img src="//image.thecupping.co.kr/partners/spinner.svg" className="spinnerImg" alt="로딩 중" />
    </div>
  );
};

export default Spinner;
