import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { HOST_BIZ, AWS_IMAGE_URL } from '@library/Constants';
import { bizStorage } from '@library/bizStorage';
import { LayoutMenuProps, MenuList, SubMenuItem } from '@type/layout';
import { Link, NavLink } from 'react-router-dom';
import { goLoginPage } from '@library/Utils';
import { StorageCompanyType } from '@src/types/storageType';

const LayoutMenu = (props: LayoutMenuProps) => {
  const { menuOpen, onMenu } = props;
  const isLogin = Cookies.get('isLogin');
  const { pathname } = window.location;
  const menuItem = bizStorage.getItem<MenuList>('menu');
  const company = bizStorage.getItem<StorageCompanyType>('company');
  const current = menuItem.find((v) => v && v.sub.find((k) => pathname.includes(k.link)));
  const currentTitle = current ? current.title : '';
  const [currentMenuTitle, setCurrentMenuTitle] = useState(currentTitle || '');

  return (
    <div className={`layoutMenu ${menuOpen ? 'open' : ''}`}>
      <div className="topAuthBox">
        <button
          type="button"
          className="btnClose"
          onClick={() => {
            onMenu(false);
          }}
        >
          <img src={`${AWS_IMAGE_URL}/icon/m-btn-x-white-16.svg`} alt="닫기" />
        </button>

        <h1>
          <Link to={`${HOST_BIZ}`}>
            <img src={`${AWS_IMAGE_URL}/image/wmfo-parters-logo-white.svg`} className="logo" alt="위메프오 파트너스" />
          </Link>
        </h1>

        {isLogin === '1' ? (
          <p className="auth login">
            반값습니다.
            <br />
            <Link to={`${HOST_BIZ}/membership/main`}>{company && company.owner} 님</Link>
            <Link to="/login/out">로그아웃</Link>
          </p>
        ) : (
          <p className="auth">
            위메프오 입점 사장님,
            <br />
            <strong>
              <button type="button" onClick={goLoginPage}>
                로그인
              </button>
              해주세요!
            </strong>
          </p>
        )}
      </div>
      <div className="scrollArea">
        <ul className="menuListBox">
          {menuItem &&
            menuItem.map((menu, idx) => {
              const { title, sub, keyword } = menu;
              if (!keyword || (company && !company.siteID && keyword === 'point')) return false;
              let isBigMenuOpen = false;
              if (currentMenuTitle === title) {
                isBigMenuOpen = true;
              }
              return (
                <li key={`${idx}-list`} className={keyword}>
                  <button
                    type="button"
                    className={`title ${isBigMenuOpen ? 'active' : ''}`}
                    onClick={() => {
                      setCurrentMenuTitle(title);
                    }}
                  >
                    {title}
                  </button>

                  {isBigMenuOpen && sub && sub.length > 0 && (
                    <ul className="subBox">
                      {sub.map((s: SubMenuItem, idxs: React.Key) => {
                        const { title: sTitle } = s;

                        let urlLink = s.link;
                        if (s.host === 'HOST_BIZ' && !s.link.includes('http')) {
                          urlLink = HOST_BIZ + s.link;
                        }

                        return (
                          <li key={idxs}>
                            {urlLink.includes('http') ? (
                              <a href={urlLink} target="_blank" rel="noreferrer">
                                {sTitle}
                              </a>
                            ) : (
                              <NavLink to={`${urlLink}`} onClick={() => onMenu(false)}>
                                {sTitle}
                              </NavLink>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
        <div className="customeBox">
          <dl>
            <dt>위메프오 사장님 전용센터</dt>
            <dd>평일/주말 11:00 ~ 22:00</dd>
            <dd className="call">1833-4877</dd>
          </dl>
          <div className="btnGroup">
            <Link to={`${HOST_BIZ}/home/introduce`} className="cs">
              <img src={`${AWS_IMAGE_URL}/icon/m-iicon-20-cs.svg`} alt="서비스 소개" />
              서비스 소개
            </Link>
            <Link to={`${HOST_BIZ}/contract/write`} className="partnership">
              <img src={`${AWS_IMAGE_URL}/icon/m-icon-20-pattnership.svg`} alt="제휴문의" />
              제휴문의
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutMenu;
