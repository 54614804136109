import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import CommonRadioButton from '@src/components/molecules/radioButton/RadioButton';
import {
  StoreTableDetailType,
  StoreTableDetailInit,
  TableAppType,
  TablePayType,
  StoreTablePutType,
  TABLEORDER_KEYS,
} from '@src/types/tableOrderType';
import { FieldValues, useForm } from 'react-hook-form';
import { StoreTableDetail, CallPostStoreTable, CallPutStoreTable } from '@src/library/request/callTableOrder';
import { bizStorage } from '@src/library/bizStorage';
import { StorageStoreListType } from '@src/types/storageType';
import { ModeType, StoreStatus } from '@src/types/pointType';
import useBackDetection from '@src/hooks/useBackDetection';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ResponseType } from '@src/types/apiType';

export const TableOrderEdit = () => {
  const navigate = useNavigate();
  const { storeID } = useParams();
  const location = useLocation();
  const { selectStore = '', prev = null } = location?.state || {};
  const storeList = bizStorage.getItem<StorageStoreListType>('storeList');

  const [requestParam, setRequestParam] = useState<StoreTableDetailType>(StoreTableDetailInit);
  const formControl = useForm<FieldValues>({
    values: requestParam,
  });
  const { getValues, setValue, watch } = formControl;

  const queryClient = useQueryClient();

  const onChangeRadio = (e: ChangeEvent, val?: number | string, resetFiled?: string) => {
    const { name } = e.target as HTMLInputElement;
    setValue(name, val);
    if (resetFiled) {
      setValue(resetFiled, null);
    }
  };

  const getTableSettings = async (storeID: string) => {
    const currentStore = storeList.find((store) => store.ID === storeID);
    const cached = queryClient.getQueryData([
      TABLEORDER_KEYS.DETAIL,
      Number(storeID),
    ]) as ResponseType<StoreTableDetail>;
    if (cached) {
      setRequestParam({ ...cached.data.storeTableDetail, status: Number(currentStore.status) });
    }
  };

  const onSave = async () => {
    const isNew = getValues('mode') && getValues('mode') === ModeType.new; // 신규 init데이터에만 mode 존재
    const callAPI = isNew ? CallPostStoreTable : CallPutStoreTable;

    const params: StoreTablePutType = {
      isTable: getValues('isTable'),
      menuType: getValues('menuType'),
      ...(getValues('isTable') && {
        payType: getValues('payType'),
        appType: getValues('appType'),
      }),
    };

    const { result } = await callAPI(storeID, params);
    return result;
  };

  const { mutate: saveMutate } = useMutation(onSave, {
    onSuccess: (result) => {
      if (result.code === 1) {
        alert('테이블 주문 설정이 완료되었습니다.');
        queryClient.invalidateQueries([TABLEORDER_KEYS.DETAIL, Number(storeID)]);
        checkGoBack();
      }
    },
  });

  const checkGoBack = () => {
    navigate('/table/setting', { state: selectStore });
  };

  useBackDetection(checkGoBack);

  useEffect(() => {
    if (prev) setRequestParam({ ...prev });
    else getTableSettings(storeID);
  }, [storeID]);

  return (
    <div className="table-page-wrap edit">
      <div className="searchArea">
        <select disabled>
          <option value={storeID}>{selectStore}</option>
        </select>
      </div>
      <div className="detailFormArea">
        {/* 테이블 주문 사용 */}
        <div className="formItem">
          <span className="title">테이블 주문 사용</span>
          <div className="radioType">
            <CommonRadioButton
              name="isTable"
              id="isTable1"
              label="사용"
              value={1}
              check={watch('isTable')}
              onChangeHandler={(e) => onChangeRadio(e, 1)}
              disabled={watch('status') === StoreStatus.stop || watch('status') === StoreStatus.input}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="isTable"
              id="isTable2"
              label="사용 안함"
              value={0}
              check={!watch('isTable')}
              onChangeHandler={(e) => onChangeRadio(e, 0)}
            />
          </div>
          <div className="notice">
            <p>
              ‘사용’ 설정 시 QR코드를 통해 주문결제 가능하며, <br />
              ‘사용 안함’ 설정 시 QR코드는 메뉴판 기능으로만 사용가능합니다.
            </p>
            <p>판매상태 ‘판매중지’인 매장의 경우 ‘사용 안함’ 설정만 가능합니다.</p>
          </div>
        </div>
        {/* 메뉴판 보기 형식 */}
        <div className="formItem">
          <span className="title">메뉴판 보기 형식</span>
          <div className="radioType">
            <CommonRadioButton
              name="menuType"
              id="menuType1"
              label="1단 스크롤"
              value={1}
              check={watch('menuType') === 1}
              onChangeHandler={(e) => onChangeRadio(e, 1)}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="menuType"
              id="menuType2"
              label="2단 스크롤"
              value={2}
              check={watch('menuType') === 2}
              onChangeHandler={(e) => onChangeRadio(e, 2)}
            />
          </div>
          <div className="radioType">
            <CommonRadioButton
              name="menuType"
              id="menuType3"
              label="3단 스크롤"
              value={3}
              check={watch('menuType') === 3}
              onChangeHandler={(e) => onChangeRadio(e, 3)}
            />
          </div>
          <button
            type="button"
            className="btn-preview"
            onClick={() => navigate('/table/setting/preview', { state: { selectStore, prev: getValues() } })}
          >
            미리보기
          </button>
          <div className="notice">
            <p>
              설정된 보기 형식에 따라 사용자가 QR코드를 통해 테이블 주문 페이지 진입 시 기본으로 노출되는 메뉴판 보기
              형식이 설정됩니다.
            </p>
          </div>
        </div>
        {watch('isTable') === 1 && (
          <>
            {/* 주문접수 채널 */}
            {/* <div className="formItem">
              <span className="title">주문접수 채널</span>
              <div className="radioType">
                <CommonRadioButton
                  name="appType"
                  id="wmpo"
                  label="위메프오"
                  value={TableAppType.wmpo}
                  check={watch('appType') === TableAppType.wmpo}
                  onChangeHandler={(e) => onChangeRadio(e, TableAppType.wmpo)}
                />
              </div>
              {watch('brandID') > 0 && (
                <div className="radioType">
                  <CommonRadioButton
                    name="appType"
                    id="wmpoPlus"
                    label="자사앱"
                    value={TableAppType.wmpoPlus}
                    check={watch('appType') === TableAppType.wmpoPlus}
                    onChangeHandler={(e) => onChangeRadio(e, TableAppType.wmpoPlus)}
                  />
                </div>
              )}
              <div className="notice">
                <p> 테이블 주문 시 App을 통한 회원 주문의 경우 주문접수되는 App의 유형을 설정합니다.</p>
              </div>
            </div> */}
            <div className="formItem">
              <dl>
                <dt className="title">주문접수 채널</dt>
                <dd>{watch('appType') === TableAppType.wmpo ? '위메프오' : '자사앱'}</dd>
              </dl>
              <div className="notice">
                <p>주문접수 채널 변경은 위메프오 관리자에게 문의해주시기 바랍니다.</p>
                <p>테이블 주문 시 App을 통한 회원 주문의 경우 주문 접수되는 App의 유형을 설정합니다.</p>
              </div>
            </div>
            {/* 결제방법 */}
            <div className="formItem">
              <dl>
                <dt className="title">결제방법</dt>
                <dd>{watch('payType') === TablePayType.app ? '바로결제' : '현장결제'}</dd>
              </dl>
              <div className="notice">
                <p>결제방법 변경은 위메프오 관리자에게 문의해주시기 바랍니다.</p>
                <p>
                  &lsquo;바로결제&rsquo; 설정 시 고객의 기기를 통해 선택한 결제 수단에 따라 결제를 진행하며,
                  &lsquo;현장결제&rsquo; 설정 시 매장에서 식사를 마친 후 매장 POS에서 직접 결제합니다.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="fixedBtnGroup">
        <button type="button" id="btnSubmit" className="btnDone" onClick={() => saveMutate()}>
          저장
        </button>
      </div>
    </div>
  );
};
