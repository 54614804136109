import React, { useEffect, useState, useRef } from 'react';
import { AWS_IMAGE_URL } from '@src/library/Constants';
import { CallGetPointHistory, CallGetPomiStores } from '@src/library/request/callPoint';
import { GetPointHistoryType, PointUseStatusEn, PointHistoryItemType, PomiStoreListType } from '@src/types/pointType';
import { DateRangeType } from '@src/types/date/dateType';
import DateRangePicker from '@src/components/molecules/datepicker/DateRangePicker';
import moment from 'moment';
import { dateToYYYYMMDD, defaultDateRangeDate } from '@type/date/dateHelper';
import { numberWithCommas } from '@src/library/Utils';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CommonPagination from '@src/components/molecules/pagination/Pagination';
import Spinner from '@src/components/molecules/Spinner';

const PointList = () => {
  const [searchParams] = useSearchParams();
  const layoutState = searchParams.get('layout');
  const [loading, setLoading] = useState<boolean>(false);
  const today = moment().toDate();
  const [dateType, setDateType] = useState<DateRangeType>(DateRangeType.RecentSevenDays);
  const [pomiStore, setPomiStore] = useState<PomiStoreListType[] | null>(null);
  const activeBtnRef = useRef<boolean>(false);
  const [searchParam, setSearchParam] = useState<GetPointHistoryType>({
    page: 1,
    limit: 10,
    executeAt: layoutState === 'none' ? 1 : 0,
    searchType: 1,
    beginDate: dateToYYYYMMDD(defaultDateRangeDate(today, dateType).s) || '',
    endDate: dateToYYYYMMDD(defaultDateRangeDate(today, dateType).e) || '',
  });

  const [historyList, setHistoryList] = useState<PointHistoryItemType[]>([
    {
      orderNo: '',
      amount: 0,
      storeID: '',
      storeName: 0,
      created: 0,
      executeType: 0,
      executeTypeText: '',
      executeAtTitle: '',
      executeAt: '',
    },
  ]);
  const [historySummery, setHistorySummery] = useState({
    totalAmount: '',
    usedAmount: '',
    remainAmount: '',
  });
  const [pointPaging, setPointPaging] = useState({
    page: 1,
    limit: 10,
    maxSize: 5,
    totalCount: 0,
  });
  const navigate = useNavigate();

  const handleSeletChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };
  const onChangeDate = (sDate: Date, eDate: Date) => {
    setSearchParam({
      ...searchParam,
      beginDate: dateToYYYYMMDD(sDate),
      endDate: dateToYYYYMMDD(eDate),
    });
  };

  const onClickSearch = async (params: GetPointHistoryType) => {
    try {
      setLoading(true);
      const { data, paging } = await CallGetPointHistory(params, 'get');
      setHistoryList(data.items);
      setHistorySummery(data.total);
      setPointPaging({ ...pointPaging, page: params.page || 1, totalCount: paging.totalCount });
      window.scrollTo(0, 0);
    } catch (e) {
      setHistoryList([]);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const onClickExcel = async (params: GetPointHistoryType) => {
    const { result } = await CallGetPointHistory(params, 'getExcel');
    if (result.code === 1) {
      alert(result.message);
    } else {
      alert(result.message);
      console.log('실패: code =', result.code);
    }
  };

  const getPomiStores = async () => {
    const { result, data } = await CallGetPomiStores();
    if (result.code === 1) {
      setPomiStore(data);
    } else {
      console.log('실패: code =', result.code, result.message);
    }
  };

  const onSearchPage = (page: number) => {
    onClickSearch({ ...searchParam, page });
  };

  const goDetail = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, storeID: string, v: PointHistoryItemType) => {
    e.preventDefault();
    navigate(`/point/list/${storeID}`, { state: v });
  };

  const onClickCalendar = () => {
    const basicBtn = document.getElementById('날짜 지정');
    const activeBtn = document.querySelector('.tabSortBox .active');
    if (activeBtn) {
      activeBtn.className = '';
      basicBtn.className = 'active';
      activeBtnRef.current = true;
    }
  };

  const onClickDateRangeBtn = async (btnId: string, dateRangeType: DateRangeType) => {
    const basicBtn = document.getElementById('날짜 지정');
    const todayBtn = document.getElementById('오늘');
    const sevenDayBtn = document.getElementById('최근 7일');
    const monthBtn = document.getElementById('최근 1개월');

    if (activeBtnRef.current) {
      await setDateType(null);
    }

    // 모든 버튼의 클래스를 초기화
    [basicBtn, todayBtn, sevenDayBtn, monthBtn].forEach((btn) => {
      btn?.classList.remove('active');
    });

    // 해당 버튼에 'active' 클래스 추가
    const activeBtn = document.getElementById(btnId);
    if (activeBtn) {
      activeBtn.classList.add('active');
    }

    setDateType(dateRangeType);
  };

  useEffect(() => {
    const d = defaultDateRangeDate(today, dateType);
    setSearchParam({
      ...searchParam,
      beginDate: d.s ? dateToYYYYMMDD(d.s) : '',
      endDate: d.e ? dateToYYYYMMDD(d.e) : '',
    });
  }, [dateType]);

  useEffect(() => {
    onClickSearch(searchParam);
  }, [layoutState]);

  useEffect(() => {
    getPomiStores();
  }, []);

  return (
    <div className="pointPageWrap history">
      <p className="notiPlus">※ 위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.</p>
      <div className="searchArea">
        <div className="searchItem">
          <select name="executeType" onChange={(e) => handleSeletChange(e)}>
            <option value="0">전체 상태</option>
            <option value="10">적립</option>
            <option value="11">적립취소</option>
            <option value="20">사용</option>
            <option value="21">사용취소</option>
            <option value="40">소멸</option>
          </select>
        </div>
        <div className="searchItem">
          <select name="storeID" onChange={(e) => handleSeletChange(e)}>
            <option value="">전체 매장</option>
            {pomiStore &&
              pomiStore.map((v, idx) => {
                const { ID, name } = v;
                return (
                  <option key={`${ID}-${idx}`} value={ID}>
                    [{ID}] {name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="searchItem">
          <select name="executeAt" onChange={(e) => handleSeletChange(e)} defaultValue={layoutState === 'none' ? 1 : 0}>
            <option value="0">전체 적립처/사용처</option>
            <option value="1">오프라인 적립</option>
            <option value="2">자사앱 적립</option>
            <option value="3">오프라인 사용</option>
            <option value="4">자사앱 사용</option>
          </select>
        </div>
        <div className="searchItem">
          <select name="searchType" onChange={(e) => handleSeletChange(e)}>
            <option value="1">휴대폰 번호</option>
            <option value="2">주문번호</option>
          </select>
        </div>
        <div className="searchItem">
          <input
            type="text"
            name="searchValue"
            placeholder="검색어를 입력해주세요."
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div className="searchItem tabSortBox">
          <button
            type="button"
            onClick={() => {
              onClickDateRangeBtn('날짜 지정', DateRangeType.Choice);
              setSearchParam({
                ...searchParam,
                beginDate: '',
                endDate: '',
              });
            }}
            className={`${dateType === DateRangeType.Choice && 'active'}`}
            id="날짜 지정"
          >
            날짜 지정
          </button>
          <button
            type="button"
            onClick={() => onClickDateRangeBtn('오늘', DateRangeType.Today)}
            className={`${dateType === DateRangeType.Today && 'active'}`}
            id="오늘"
          >
            오늘
          </button>
          <button
            type="button"
            onClick={() => onClickDateRangeBtn('최근 7일', DateRangeType.RecentSevenDays)}
            className={`${dateType === DateRangeType.RecentSevenDays && 'active'}`}
            id="최근 7일"
          >
            최근 7일
          </button>
          <button
            type="button"
            onClick={() => onClickDateRangeBtn('최근 1개월', DateRangeType.RecentOneMonth)}
            className={`${dateType === DateRangeType.RecentOneMonth && 'active'}`}
            id="최근 1개월"
          >
            최근 1개월
          </button>
        </div>

        <div className="searchItem datePickerBox">
          <DateRangePicker
            startDate={searchParam.beginDate ? moment(searchParam.beginDate).toDate() : undefined}
            endDate={searchParam.endDate ? moment(searchParam.endDate).toDate() : undefined}
            isPeriod
            onChange={onChangeDate}
            startPlaceholder="조회기간 시작일"
            endPlaceholder="조회기간 종료일"
            isIcon={false}
            isThreeMonthsAgo
            onClick={() => onClickCalendar()}
          />
        </div>
        <button
          type="button"
          className="searchItem btnSearch"
          onClick={() => {
            onClickSearch(searchParam);
          }}
        >
          <img src={`${AWS_IMAGE_URL}/icon/pc-icon-40-search.svg`} alt="검색" /> 검색
        </button>
        <p className="notice">※ 포인트 내역은 최근 3개월까지 조회하실 수 있습니다.</p>
      </div>
      <div className="summeryBox">
        <div className="summeryItem">
          <span className="label">총 적립 포인트</span>
          <span className="value total">
            {numberWithCommas(historySummery.totalAmount)} <em>P</em>
          </span>
        </div>
        <div className="summeryItem">
          <span className="label">사용 완료 포인트</span>
          <span className="value">
            {numberWithCommas(historySummery.usedAmount)} <em>P</em>
          </span>
        </div>
        <div className="summeryItem">
          <span className="label">사용 가능 포인트</span>
          <span className="value use">
            {numberWithCommas(historySummery.remainAmount)} <em>P</em>
          </span>
        </div>
      </div>
      <ul className="pointListWrap">
        {historyList.length ? (
          historyList.map((v, i) => {
            const {
              storeID,
              storeName,
              created,
              executeType,
              executeTypeText,
              amount,
              executeAtTitle,
              executeAt,
              expireType,
            } = v;
            return (
              <Link to="/" key={`${i}-${storeID}`} onClick={(e) => goDetail(e, storeID, v)}>
                <li className="pointList">
                  <div className="pointListTitle">
                    <strong className="point">{Number(amount).toLocaleString()} P</strong>
                    <span className={`stateLabel ${PointUseStatusEn[executeType]}`}>{executeTypeText}</span>
                  </div>
                  <ul>
                    <li>
                      <em>매장명</em>
                      <span>{storeName}</span>
                    </li>
                    <li>
                      <em>{executeAtTitle}</em>
                      <span>{executeType === 40 ? expireType : executeAt}</span>
                    </li>
                    <li>
                      <em>일시</em>
                      <span>{created}</span>
                    </li>
                  </ul>
                </li>
              </Link>
            );
          })
        ) : (
          <p className="noListBox">포인트 내역이 없습니다.</p>
        )}
      </ul>
      <CommonPagination {...pointPaging} onSearch={onSearchPage} />
      <div className="fixedBtnGroup">
        <button
          type="button"
          id="btnSubmit"
          className="btnDone"
          onClick={() => {
            onClickExcel(searchParam);
          }}
        >
          이메일 전송
        </button>
      </div>

      <Spinner show={loading} />
    </div>
  );
};

export default PointList;
