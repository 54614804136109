import React, { useState } from 'react';
import { DefaultLayoutProps } from '@type/layout';
import Header from './Header';
import LayoutMenu from './LeftMenu';

function DefaultLayout(props: DefaultLayoutProps) {
  const { children, title, backUrl, message, wrapClassName } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const onMenu = (flag: boolean) => {
    setMenuOpen(flag);
  };

  return (
    <div id="layoutWrap" className="layoutWrap">
      <div className="container">
        <Header title={title} onMenu={onMenu} backUrl={backUrl} message={message} />
        <div className="contentWrap">
          <LayoutMenu menuOpen={menuOpen} onMenu={onMenu} />
          <main id="content" className={`content ${wrapClassName}`}>
            <h2>{title}</h2>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
