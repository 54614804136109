import Spinner from '@src/components/molecules/Spinner';
import { extractNumber } from '@src/library/Utils';
import { bizStorage } from '@src/library/bizStorage';
import { CallGetStoreTable } from '@src/library/request/callTableOrder';
import { StoreStatusKo } from '@src/types/pointType';
import { StorageStoreListType } from '@src/types/storageType';
import {
  StoreTableDetailType,
  StoreTableDetailInit,
  TableAppType,
  TablePayType,
  TABLEORDER_KEYS,
} from '@src/types/tableOrderType';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { selectOptions } from '../list/list';

export const TableOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectStore = location?.state || ''; // 수정 페이지에서 받아오는 select value
  const storeList = bizStorage.getItem<StorageStoreListType>('storeList');

  const [detail, setDetail] = useState<StoreTableDetailType>(StoreTableDetailInit);
  const [currentStoreID, setCurrentStoreID] = useState(0);
  const currentStoreRef = useRef<string | number>();

  const queryClient = useQueryClient();

  const {
    isError,
    isLoading,
    data: settingData,
  } = useQuery([TABLEORDER_KEYS.DETAIL, currentStoreID], () => CallGetStoreTable(currentStoreID), {
    enabled: !!currentStoreID,
  });

  const handleStoreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setCurrentStoreID(extractNumber(selectedValue));
    currentStoreRef.current = selectedValue;

    queryClient.refetchQueries([TABLEORDER_KEYS.DETAIL, extractNumber(selectedValue)]);
  };

  useEffect(() => {
    if (!settingData) return;
    const { result, data } = settingData;
    const currentStore = storeList?.find((store) => Number(store.ID) === data.storeTableDetail.storeID);
    if (result.code === 1) {
      setDetail({ ...data.storeTableDetail, status: Number(currentStore?.status) });
    } else if (result.code === 401) {
      // 미등록 상태일 경우 기본값 세팅
      setDetail({ ...StoreTableDetailInit, status: Number(currentStore?.status) });
    }
  }, [settingData]);

  useEffect(() => {
    const storeID = selectStore ? extractNumber(selectStore) : Number(storeList[0]?.ID);
    setCurrentStoreID(storeID);
    if (selectStore) {
      currentStoreRef.current = selectStore;
      navigate(location.pathname, { replace: true }); // location.state 초기화
    } else {
      currentStoreRef.current = `[${StoreStatusKo[Number(storeList[0]?.status)]}] [${storeID}] ${storeList[0]?.name}`;
    }
  }, []);

  if (isError || !storeList) return <Spinner />;

  return (
    <div className="table-page-wrap order">
      <div className="searchArea">
        <select onChange={(e) => handleStoreChange(e)} value={selectStore || currentStoreRef.current}>
          {selectOptions(storeList)}
        </select>
      </div>
      <div className="detail-area">
        <dl>
          <dt>테이블 주문 사용</dt>
          <dd>{detail?.isTable ? '사용' : '사용 안함'}</dd>
          <dt>메뉴판 보기 형식</dt>
          <dd>{detail?.menuType}단 스크롤</dd>
          {detail?.isTable === 1 && (
            <>
              {detail?.appType && (
                <>
                  <dt>주문접수 채널</dt>
                  <dd>{detail?.appType === TableAppType.wmpo ? '위메프오' : '자사앱'}</dd>
                </>
              )}
              {detail?.payType && (
                <>
                  <dt>결제방법</dt>
                  <dd>{detail.payType === TablePayType.app ? '바로결제' : '현장결제'}</dd>
                </>
              )}
            </>
          )}
        </dl>
      </div>
      <div className="fixedBtnGroup">
        <button
          type="button"
          id="btnSubmit"
          className="btnDone"
          onClick={() =>
            navigate(`/table/setting/${currentStoreID}`, { state: { selectStore: currentStoreRef.current } })
          }
        >
          수정
        </button>
      </div>
      <Spinner show={isLoading} />
    </div>
  );
};
