export enum DateRangeType {
  Choice = 0,
  Today = 1,
  Yesterday = 2,
  RecentSevenDays = 3,
  RecentOneMonth = 4,
  RecentThreeMonths = 5,
  Self = 6,
  RecentSevenPrevDays = 7,
  RecentOnePrevMonth = 8,
  ThisMonth = 9,
  LastMonth = 10,
}
