import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import DateRangePicker from '@components/molecules/datepicker/DateRangePicker';
import CommonPagination from '@components/molecules/pagination/Pagination';
import Spinner from '@components/molecules/Spinner';
import { AWS_IMAGE_URL } from '@src/library/Constants';
import { CallGetPointList } from '@library/request/callPoint';
import { PointStatusEn, PointStatusKo, pointTypeOptions, PointOnlineType } from '@type/pointType';
import { DateRangeType } from '@type/date/dateType';
import { dateToYYYYMMDD, defaultDateRangeDate } from '@type/date/dateHelper';
import { numberWithCommas } from '@library/Utils';

type SearchType = {
  page?: number;
  limit?: number;
  status?: number;
  name?: string;
  // 리스트 조회 시 해당 값으로 조회
  searchStartDate?: string;
  searchEndDate?: string;
};

const getNonZeroReserveRate = (reserveAmount: string | number, reserveRate: string): string => {
  return Number(reserveRate) === 0 ? `${numberWithCommas(reserveAmount)}P` : `${Number(reserveRate)}%`;
};

export const getEndDate = (date: string) => {
  const isEndDateCheck = date?.split('.')[0] === '9999';
  return isEndDateCheck ? '종료일 없음' : date;
};

const PointOnline = () => {
  const navigate = useNavigate();
  const today = moment().toDate();
  const location = useLocation();
  const prevSearchParam = location?.state?.prevSearchParam || null; // 상세 페이지에서 받아온 기존 검색 조건
  const prevDateType = location?.state?.prevDateType || 0; // 상세 페이지에서 받아온 기존 날짜 타입 조건
  const activeBtnRef = useRef<boolean>(false);
  const [dateType, setDateType] = useState<DateRangeType>(0);
  const [pointList, setPointList] = useState<PointOnlineType[] | []>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<SearchType>({
    page: 1,
    limit: 10,
    name: '',
  });
  const [pointPaging, setPointPaging] = useState({
    page: 1,
    limit: 10,
    maxSize: 5,
    totalCount: 0,
  });

  const onChangeDate = (sDate: Date, eDate: Date) => {
    setSearchParam({
      ...searchParam,
      page: 1,
      searchStartDate: dateToYYYYMMDD(sDate),
      searchEndDate: dateToYYYYMMDD(eDate),
    });
  };

  const onClickSearch = async (params: SearchType) => {
    try {
      setLoading(true);
      const { paging, data = [] } = await CallGetPointList(params);
      setPointList(data);
      setPointPaging({ ...pointPaging, page: params.page || 1, limit: paging.count, totalCount: paging.totalCount });
      window.scrollTo(0, 0);
    } catch (e) {
      setPointList([]);
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const onSearchPage = (page: number) => {
    setSearchParam({ ...searchParam, page });
    onClickSearch({ ...searchParam, page });
  };

  const onClickCalendar = () => {
    const basicBtn = document.getElementById('날짜 지정');
    const activeBtn = document.querySelector('.tabSortBox .active');
    if (activeBtn) {
      activeBtn.className = '';
      basicBtn.className = 'active';
      activeBtnRef.current = true;
    }
  };

  const onClickDateRangeBtn = async (btnId: string, dateRangeType: DateRangeType) => {
    const basicBtn = document.getElementById('날짜 지정');
    const todayBtn = document.getElementById('오늘');
    const sevenDayBtn = document.getElementById('최근 7일');
    const monthBtn = document.getElementById('최근 1개월');

    if (activeBtnRef.current) {
      await setDateType(null);
    }

    // 모든 버튼의 클래스를 초기화
    [basicBtn, todayBtn, sevenDayBtn, monthBtn].forEach((btn) => {
      btn?.classList.remove('active');
    });

    // 해당 버튼에 'active' 클래스 추가
    const activeBtn = document.getElementById(btnId);
    if (activeBtn) {
      activeBtn.classList.add('active');
    }

    setDateType(dateRangeType);
  };

  useEffect(() => {
    const d = defaultDateRangeDate(today, dateType);
    setSearchParam({
      ...searchParam,
      page: 1,
      searchStartDate: d.s ? dateToYYYYMMDD(d.s) : searchParam.searchStartDate,
      searchEndDate: d.e ? dateToYYYYMMDD(d.e) : searchParam.searchEndDate,
    });
  }, [dateType]);

  useEffect(() => {
    if (prevSearchParam) {
      if (prevDateType) setDateType(prevDateType);
      setSearchParam(prevSearchParam);
      onClickSearch(prevSearchParam);
      navigate(location.pathname, { replace: true }); // location.state 초기화
    } else {
      onClickSearch(searchParam);
    }
  }, []);

  return (
    <div className="pointPageWrap online">
      <p className="notiPlus">※ 위메프오 플러스 서비스 이용 매장에서 사용 가능한 메뉴입니다.</p>
      <div className="searchArea">
        <select
          className="searchItem"
          value={searchParam.status}
          onChange={(e) => {
            setSearchParam({
              ...searchParam,
              status: Number(e.target.value) === 9 ? undefined : Number(e.target.value),
            });
          }}
        >
          {pointTypeOptions.map((option, index) => {
            const { name, value } = option;
            return (
              <option key={`${name}_${index}`} value={value}>
                {name}
              </option>
            );
          })}
        </select>

        <input
          type="text"
          className="searchItem"
          placeholder="포인트명을 입력해주세요."
          value={searchParam?.name || ''}
          onChange={(e) => {
            const { value } = e.target;
            setSearchParam({ ...searchParam, name: value });
          }}
        />

        <div className="searchItem tabSortBox">
          <button
            type="button"
            onClick={() => {
              onClickDateRangeBtn('날짜 지정', DateRangeType.Choice);
              setSearchParam({
                ...searchParam,
                searchStartDate: '',
                searchEndDate: '',
              });
            }}
            className={`${dateType === DateRangeType.Choice && 'active'} basic`}
            id="날짜 지정"
          >
            날짜 지정
          </button>
          <button
            type="button"
            onClick={() => onClickDateRangeBtn('오늘', DateRangeType.Today)}
            className={`${dateType === DateRangeType.Today && 'active'}`}
            id="오늘"
          >
            오늘
          </button>
          <button
            type="button"
            onClick={() => onClickDateRangeBtn('최근 7일', DateRangeType.RecentSevenDays)}
            className={`${dateType === DateRangeType.RecentSevenDays && 'active'}`}
            id="최근 7일"
          >
            최근 7일
          </button>
          <button
            type="button"
            onClick={() => onClickDateRangeBtn('최근 1개월', DateRangeType.RecentOneMonth)}
            className={`${dateType === DateRangeType.RecentOneMonth && 'active'}`}
            id="최근 1개월"
          >
            최근 1개월
          </button>
        </div>

        <div className="searchItem datePickerBox">
          <DateRangePicker
            startDate={searchParam.searchStartDate ? moment(searchParam.searchStartDate).toDate() : undefined}
            endDate={searchParam.searchEndDate ? moment(searchParam.searchEndDate).toDate() : undefined}
            isPeriod
            onChange={onChangeDate}
            startPlaceholder="등록기간 시작일"
            endPlaceholder="등록기간 종료일"
            isIcon={false}
            onClick={() => onClickCalendar()}
          />
        </div>

        <button type="button" className="searchItem btnSearch" onClick={() => onSearchPage(1)}>
          <img src={`${AWS_IMAGE_URL}/icon/pc-icon-40-search.svg`} alt="검색" /> 검색
        </button>
      </div>
      <div className="pointListWrap">
        {pointList.length ? (
          pointList.map((list: PointOnlineType) => {
            const { eventID, name, reserveRate, reserveAmount, beginDate, endDate, status, created } = list;
            return (
              <button
                key={eventID}
                type="button"
                className="pointListBtn"
                onClick={() => navigate(`/point/online/detail/${eventID}`, { state: { searchParam, dateType } })}
              >
                <div className="pointList">
                  <div className="pointListTitle">
                    <strong className="name">{name}</strong>
                    <span className={`stateLabel ${PointStatusEn[status]}`}>{PointStatusKo[status]}</span>
                  </div>
                  <ul>
                    <li>
                      <em>적립방법</em>
                      <span>{getNonZeroReserveRate(reserveAmount, reserveRate)}</span>
                    </li>
                    <li>
                      <em>적립기간</em>
                      <span>{`${moment(beginDate).format('YYYY.MM.DD')}~${getEndDate(
                        moment(endDate).format('YYYY.MM.DD'),
                      )}`}</span>
                    </li>
                    <li>
                      <em>등록일</em>
                      <span>{moment(created).format('YYYY.MM.DD') || ''}</span>
                    </li>
                  </ul>
                </div>
              </button>
            );
          })
        ) : (
          <p className="noListBox">자사앱 포인트 설정 내역이 없습니다.</p>
        )}
        <CommonPagination {...pointPaging} onSearch={onSearchPage} />
      </div>
      <div className="fixedBtnGroup">
        <button type="button" id="btnSubmit" className="btnDone" onClick={() => navigate('/point/online/add')}>
          등록
        </button>
      </div>
      <Spinner show={loading} />
    </div>
  );
};

export default PointOnline;
