import { StorageKeyType } from '@src/types/storageType';

export const bizStorage = {
  getItem<T>(key: StorageKeyType): T {
    return JSON.parse(sessionStorage.getItem(key));
  },
  setItem<T>(key: StorageKeyType, value: T): void {
    return sessionStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key: StorageKeyType): void {
    return sessionStorage.removeItem(key);
  },
  removeAll(): void {
    return sessionStorage.clear();
  },
};
